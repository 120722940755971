import {
  GET_ALL_CARDS,
  SYNC_CARDS,
  SET_LOADING,
  SET_LOADING_SYNC_CARDS,
  CARDS_ERROR,
  SYNC_CARDS_ERROR
} from '../actions/types';

const initialState = {
  allCards: null,
  allCardsCount: 0,
  loading: false,
  syncResult: null,
  syncLoading: false,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CARDS:
      return {
        ...state,
        allCards: payload?.cards,
        allCardsCount: payload?.totalCards,
        loading: false
      };
    case SYNC_CARDS:
      return {
        ...state,
        syncResult: payload,
        syncLoading: false
      };
    case SET_LOADING:
      return {
        ...state,
        allCards: null,
        allCardsCount: 0,
        loading: true
      };
    case SET_LOADING_SYNC_CARDS:
      return {
        ...state,
        syncResult: null,
        syncLoading: true
      };
    case CARDS_ERROR:
      return {
        ...state,
        allCards: null,
        allCardsCount: 0,
        loading: false,
        error: 'Error'
      };
    case SYNC_CARDS_ERROR:
      return {
        ...state,
        syncResult: null,
        syncLoading: false
      };
    default:
      return state;
  }
}
