/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import LoadingButton from 'src/components/Buttons/LoadingButton';
import { ADMIN } from 'src/strings/roles';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import CircularLoading from 'src/components/Loading/CircularLoading';

function AddSeller(props) {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    api_key: '',
    region: '',
    selectedPackages: []
  });

  const [state, setState] = useState({
    packages: [],
    packagesLoading: true,
    submitLoading: false,
    errorField: '',
    errorFieldMessage: ''
  });

  const { username, password, api_key, region, selectedPackages } = formData;
  const {
    packages,
    packagesLoading,
    submitLoading,
    errorField,
    errorFieldMessage
  } = state;
  const { user, open, onClose, closeAfterAdd } = props;

  const isAdmin = ADMIN === user.role.code;

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('packages/');
      setState({
        ...state,
        packages: res.data,
        packagesLoading: false
      });
    };
    fetchData();
  }, []);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCheckBoxChange = e => {
    const packageName = e.target.name;
    const pack = packages.filter(pack => pack.code === packageName)[0];

    if (e.target.checked) {
      setFormData({
        ...formData,
        selectedPackages: [...selectedPackages, pack.id]
      });
    } else {
      setFormData({
        ...formData,
        selectedPackages: selectedPackages.filter(_pack => _pack !== pack.id)
      });
    }
  };

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true
    });

    let _formData = {
      ...formData,
      packages_ids: formData.selectedPackages
    };

    if (!isAdmin) {
      delete _formData.api_key;
      delete _formData.packages_ids;
    }

    try {
      await axios.post('/sellers', _formData);

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterAdd();

      // Close Modal
    } catch (error) {
      const {
        response: {
          data: { errorField, msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorField,
        errorFieldMessage: msg
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Add Seller" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={errorField === 'username'}
                fullWidth
                label="Username"
                name="username"
                onChange={handleChange}
                required
                value={username}
                variant="outlined"
                helperText={errorField === 'username' ? errorFieldMessage : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={errorField === 'password'}
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                required
                value={password}
                variant="outlined"
                type="password"
                helperText={errorField === 'password' ? errorFieldMessage : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={errorField === 'region'}
                fullWidth
                label="Region"
                name="region"
                onChange={handleChange}
                required
                value={region}
                variant="outlined"
                helperText={errorField === 'region' ? errorFieldMessage : ''}
              />
            </Grid>

            {ADMIN === user.role.code && (
              <Grid item xs={12}>
                <TextField
                  error={errorField === 'api_key'}
                  fullWidth
                  label="API Key"
                  name="api_key"
                  onChange={handleChange}
                  required
                  value={api_key}
                  variant="outlined"
                  helperText={errorField === 'api_key' ? errorFieldMessage : ''}
                />
              </Grid>
            )}
            {isAdmin && (
              <Grid item xs={12}>
                <Typography>Packages:</Typography>
                {packagesLoading ? (
                  <CircularLoading />
                ) : (
                  <FormGroup row>
                    {packages.map(pack => (
                      <FormControlLabel
                        key={pack.id}
                        control={
                          <Checkbox
                            row-id={pack.id}
                            checked={selectedPackages.includes(pack.id)}
                            onChange={handleCheckBoxChange}
                            name={pack.code}
                          />
                        }
                        label={pack.label}
                      />
                    ))}
                  </FormGroup>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <DialogActions>
        {/*         <Button
          variant="contained"
          color="secondary"
          align="right"
          // className={classes.button}
          // onClick={this.handleSubmit}
          // disabled={!label}
        >
          Close
        </Button> */}
        <LoadingButton
          variant="contained"
          color="secondary"
          align="right"
          text="Add"
          loading={submitLoading}
          disabled={
            submitLoading ||
            !username ||
            password.length < 6 ||
            !region ||
            (!api_key && isAdmin) ||
            (selectedPackages.length < 1 && isAdmin)
          }
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}

AddSeller.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterAdd: PropTypes.func.isRequired
};

AddSeller.defaultProps = {
  open: false
};

export default AddSeller;
