import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import {
  makeStyles,
  Container,
  Hidden,
  IconButton,
  useTheme,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import { MessageSquare as MessageSquareIcon } from 'react-feather';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ADMIN } from 'src/strings/roles';

import SideBar from './messageLayout/SideBar';
import ListMessages from './messageLayout/ListMessages';
import AreaTypeMessage from './messageLayout/AreaTypeMessage';

import DialogChat from './messageLayout/chat/DialogChat';

import {
  getContacts,
  sendMessage,
  addMessage,
  setContact,
  unsetContact,
  getOlderMessages,
  filterContacts
} from 'src/actions/chat';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    minWidth: 650
  },
  chatSection: {
    width: '100%',
    height: '81vh'
  },
  NameProfile: {
    marginLeft: '20px'
  },
  profile: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const MessagesPage = ({
  socketRef,
  user,
  messages,
  messagesLoading,
  contact,
  contactList,
  contactFilterListLoading,
  // contactListLoading,
  unreadMessages,
  olderMessagesLoading,
  olderMessagesOffset,
  olderMessagesLimitReached,
  getContacts,
  sendMessage,
  addMessage,
  setContact,
  unsetContact,
  getOlderMessages,
  filterContacts
}) => {
  const classes = useStyles();
  const isAdmin = user.role.code === ADMIN || false;
  const contactInfo = contactList.find(item => item.id === contact);

  // breackpoint
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  // dialog contact

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  // message data
  const [seenMessages, setSeenMessages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const contacts = await getContacts();
      await setContact(contacts.length > 0 ? contacts[0].id : 0);
    };

    fetchData();

    return () => unsetContact();
  }, []);

  // open dialog contact
  const handleClickOpen = () => {
    setOpen(true);
  };
  // close dialog contact
  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  // messages
  const handleSendMessage = async messageData => {
    const message = await sendMessage(messageData);
    socketRef.emit('message', message);
  };

  // seen messages
  const seenMessage = readMessage => {
    setSeenMessages(readMessage);
  };

  if (contactList.length > 0 && contact === 0) {
    return (
      <Box p={5} style={{ margin: '0 auto', display: 'block' }}>
        <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
      </Box>
    );
  }

  if (contactList.length < 1) {
    return (
      <Box
        p={5}
        style={{ margin: '0 auto', display: 'block' }}
        textAlign="center"
      >
        <Typography variant="h3">
          You don&apos;t have any contacts to message yet
        </Typography>
      </Box>
    );
  }

  return (
    <Page className={classes.root} title="Messages">
      <Container maxWidth="lg">
        {isAdmin ? (
          <Grid container component={Paper} className={classes.chatSection}>
            {/* side bar */}
            <Hidden mdDown>
              <SideBar filterContacts={filterContacts} />
            </Hidden>
            {/* messageArea */}
            <Grid item xs={matches ? 12 : 9}>
              {/* name person*/}
              <List className={classes.profile}>
                <Hidden lgUp>
                  <IconButton color="inherit" onClick={handleClickOpen}>
                    <MessageSquareIcon />
                  </IconButton>
                  <DialogChat
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                    messages={messages}
                    filterContacts={filterContacts}
                  />
                </Hidden>
                <ListItemText className={classes.NameProfile}>
                  {contactInfo.username}
                </ListItemText>
              </List>
              <Divider className={classes.divider} light />
              {/* Messages between the two parties */}
              {messagesLoading ? (
                <Box p={5} style={{ margin: '0 auto', display: 'block' }}>
                  <CircularProgress
                    style={{ margin: '0 auto', display: 'block' }}
                  />
                </Box>
              ) : (
                <ListMessages
                  user={user}
                  contact={contact}
                  messages={messages}
                  messagesLoading={messagesLoading}
                  olderMessagesLoading={olderMessagesLoading}
                  olderMessagesOffset={olderMessagesOffset}
                  olderMessagesLimitReached={olderMessagesLimitReached}
                  getOlderMessages={getOlderMessages}
                  readMessage={seenMessages}
                />
              )}
              <Divider light />
              {/* type message  */}
              {!messagesLoading && (
                <AreaTypeMessage
                  user={user}
                  contact={contact}
                  sendMessage={handleSendMessage}
                  seenMessage={seenMessage}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container component={Paper} className={classes.chatSection}>
            {/* messageArea */}
            <Grid item xs={12}>
              {/* name person*/}
              <List className={classes.profile}>
                {isAdmin && (
                  <Hidden lgUp>
                    <IconButton color="inherit" onClick={handleClickOpen}>
                      <MessageSquareIcon />
                    </IconButton>
                    <DialogChat
                      selectedValue={selectedValue}
                      open={open}
                      onClose={handleClose}
                      messages={messages}
                      filterContacts={filterContacts}
                    />
                  </Hidden>
                )}
                <ListItemText className={classes.NameProfile}>
                  {contactInfo.username}
                </ListItemText>
              </List>
              <Divider className={classes.divider} light />
              {/* Messages between the two parties */}
              {messagesLoading ? (
                <Box p={5} style={{ margin: '0 auto', display: 'block' }}>
                  <CircularProgress
                    style={{ margin: '0 auto', display: 'block' }}
                  />
                </Box>
              ) : (
                <ListMessages
                  user={user}
                  contact={contact}
                  messages={messages}
                  messagesLoading={messagesLoading}
                  olderMessagesLoading={olderMessagesLoading}
                  olderMessagesOffset={olderMessagesOffset}
                  olderMessagesLimitReached={olderMessagesLimitReached}
                  getOlderMessages={getOlderMessages}
                  readMessage={seenMessages}
                />
              )}
              <Divider light />
              {/* type message  */}
              {!messagesLoading && (
                <AreaTypeMessage
                  user={user}
                  contact={contact}
                  sendMessage={handleSendMessage}
                  seenMessage={seenMessage}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  socketRef: state.ui.socketRef,
  user: state.auth.user,
  messages: state.chat.messages,
  messagesLoading: state.chat.messagesLoading,
  contact: state.chat.contact,
  contactList: state.chat.contactList,
  contactFilterListLoading: state.chat.contactFilterListLoading,
  // contactListLoading: state.chat.contactListLoading,
  unreadMessages: state.chat.unreadMessages,
  olderMessagesLoading: state.chat.olderMessagesLoading,
  olderMessagesOffset: state.chat.olderMessagesOffset,
  olderMessagesLimitReached: state.chat.olderMessagesLimitReached
});

export default connect(mapStateToProps, {
  getContacts,
  sendMessage,
  addMessage,
  setContact,
  unsetContact,
  getOlderMessages,
  filterContacts
})(MessagesPage);
