import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Box, Container, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';

import Toolbar from './Toolbar';
import SetCards from './SetCards';
import tableSettings from './tableSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function SellersCards() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [state, setState] = useState({
    seller: null,
    sellerLoading: true,
    cards: [],
    totalCards: 0,
    cardsLoading: true,
    setCardsOpen: false,
    selectedCards: [],
    unauthorizsed: false,
    // editSellerOpen: false,
    // editSellerTarget: null,
    tableKey: 0
  });
  const { id: sellerId } = useParams();

  const childStateGetter = data => {
    setState({ ...state, selectedCards: data.selectedRowsIds });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`users/${sellerId}`);
      setState({
        ...state,
        seller: res.data,
        sellerLoading: false
      });
    };
    fetchData();
  }, []);

  const handleSetCardsOpen = () => {
    setState({
      ...state,
      setCardsOpen: true
    });
  };

  const handleSetCardsClose = () => {
    setState({
      ...state,
      setCardsOpen: false
    });
  };
  const handleSetCardsCloseAfterSet = () => {
    setState(prevState => ({
      ...state,
      setCardsOpen: false,
      tableKey: prevState.tableKey + 1
    }));
  };

  const GetSellerCards = async params => {
    await setState({
      ...state,
      loading: true
    });

    try {
      const res = await axios.get(`cards/${sellerId}${params}`);

      setState({
        ...state,
        cards: res.data.cards,
        totalCards: res.data.totalCards,
        cardsLoading: false
      });
    } catch (error) {
      const { response } = error;
      if (response.status === 401) {
        navigate('/unauthorized');
      }
      console.log(response);
    }
  };

  const {
    seller,
    sellerLoading,
    cards,
    totalCards,
    cardsLoading,
    selectedCards,
    setCardsOpen,
    tableKey
  } = state;

  // Adding actions to our table
  const actions = [];

  return (
    <Page
      className={classes.root}
      title={sellerLoading ? 'Seller Cards' : `${seller.username} Cards`}
    >
      <Container maxWidth={false}>
        <Toolbar
          cardsTotal={selectedCards.length}
          handleSetCardsOpen={handleSetCardsOpen}
        />
        <Box mt={3}>
          {!sellerLoading && (
            <DataTable
              key={tableKey}
              rows={cards}
              totalrows={totalCards}
              loading={cardsLoading ? 1 : 0}
              actions={actions}
              rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
              settings={tableSettings}
              rowsGetter={GetSellerCards}
              onChange={childStateGetter}
            />
          )}
        </Box>

        {setCardsOpen && (
          <SetCards
            open={setCardsOpen}
            target={seller}
            selectedCards={selectedCards}
            onClose={handleSetCardsClose}
            closeAfterSet={handleSetCardsCloseAfterSet}
          />
        )}
      </Container>
    </Page>
  );
}

export default SellersCards;
