/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import clsx from 'clsx';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import AlertMessage from 'src/components/Messages/AlertMessage';
import { ADMIN } from 'src/strings/roles';
import { loadUser } from 'src/actions/auth';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, user, loadUser, ...rest }) => {
  const classes = useStyles();
  const isAdmin = ADMIN === user.role.code;

  const [values, setValues] = useState({
    username: '',
    api_key: '',
    region: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    submitLoading: false,
    errorMessage: '',
    successMessage: ''
  });

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let newState = {
      ...values,
      username: user.username,
      region: user.region
    };
    if (isAdmin) {
      newState.api_key = user.api_key;
    }

    setValues(newState);
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async () => {
    await setValues({
      ...values,
      submitLoading: true,
      errorMessage: '',
      successMessage: ''
    });

    const {
      username,
      api_key,
      region,
      oldPassword,
      newPassword,
      confirmPassword
    } = values;

    if (newPassword.length > 0 && newPassword.length < 6) {
      await setValues({
        ...values,
        submitLoading: false,
        errorMessage: "Password can't be less than 6 characters"
      });

      return;
    }

    if (newPassword !== confirmPassword) {
      await setValues({
        ...values,
        submitLoading: false,
        errorMessage: "Passwords don't match"
      });

      return;
    }

    if (!oldPassword && newPassword) {
      await setValues({
        ...values,
        submitLoading: false,
        errorMessage: 'Old password is not correct'
      });

      return;
    }

    let formData = {
      username,
      api_key,
      region
    };

    if (newPassword) {
      formData = {
        ...formData,
        password: newPassword,
        old_password: oldPassword
      };
    }

    try {
      await axios.put('/users/0', formData);

      await loadUser();

      setValues({
        ...values,
        submitLoading: false,
        errorMessage: '',
        successMessage: 'Your data was saved successfully'
      });
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setValues({
        ...values,
        submitLoading: false,
        errorMessage: msg,
        successMessage: ''
      });
    }
  };

  const {
    username,
    api_key,
    region,
    oldPassword,
    newPassword,
    confirmPassword,
    errorMessage,
    successMessage,
    submitLoading
  } = values;

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="If you don't want to change your password, leave the password fields empty"
          title="Account"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                onChange={handleChange}
                required
                value={username}
                variant="outlined"
              />
            </Grid>
            {isAdmin && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="API Key"
                  name="api_key"
                  onChange={handleChange}
                  required
                  value={api_key}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Region"
                name="region"
                onChange={handleChange}
                required
                value={region}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Old Password"
                name="oldPassword"
                onChange={handleChange}
                required
                value={oldPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="New Password"
                name="newPassword"
                onChange={handleChange}
                required
                value={newPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                required
                value={confirmPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <AlertMessage message={errorMessage} />
              </Grid>
            )}
            {successMessage && (
              <Grid item xs={12}>
                <AlertMessage
                  type="success"
                  title="Success"
                  message={successMessage}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Save"
            loading={submitLoading}
            disabled={
              submitLoading ||
              !username ||
              (!api_key && isAdmin) ||
              !region ||
              (newPassword.length < 1 && confirmPassword.length > 0) ||
              (newPassword.length > 0 && confirmPassword.length < 1)
            }
            onClick={handleSubmit}
          />
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default connect(null, {
  loadUser
})(ProfileDetails);
