/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  GET_ALL_CARDS,
  SYNC_CARDS,
  SET_LOADING,
  SET_LOADING_SYNC_CARDS,
  CARDS_ERROR,
  SYNC_CARDS_ERROR
} from './types';

// Get all cards
export const getAllCards = () => async dispatch => {
  dispatch({ type: SET_LOADING });
  try {
    const res = await axios.get('cards/1/500');

    dispatch({ type: GET_ALL_CARDS, payload: res.data });
  } catch (err) {
    dispatch({ type: CARDS_ERROR });
  }
};

// Sync all cards
export const syncCards = () => async dispatch => {
  dispatch({ type: SET_LOADING_SYNC_CARDS });
  try {
    const res = await axios.get('cards/sync');

    dispatch({ type: SYNC_CARDS, payload: res.data });
  } catch (err) {
    dispatch({ type: SYNC_CARDS_ERROR });
  }
};

// Sync all cards
export const setLoadingSyncCards = () => async dispatch => {
  dispatch({ type: SET_LOADING_SYNC_CARDS });
};
