/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import {
  CreditCard,
  Edit2,
  Trash2,
  XCircle,
  Play,
  DollarSign,
  CheckCircle
} from 'react-feather';

const RowAction = ({ id, className, icon, func, tooltip, isVisible }) => {
  const iconSetter = name => {
    switch (name) {
      case 'dollar-sign':
        return <DollarSign />;
      case 'credit-card':
        return <CreditCard />;
      case 'edit':
        return <Edit2 />;
      case 'delete':
        return <Trash2 />;
      case 'add-subscription':
        return <Play />;
      case 'stop-subscription':
        return <XCircle />;
      case 'accept-subscription':
        return <CheckCircle />;
      default:
        return <></>;
    }
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={className}
        onClick={func}
        aria-label={tooltip}
        row-id={id}
      >
        {iconSetter(icon)}
      </IconButton>
    </Tooltip>
  );
};
RowAction.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired
};

export default RowAction;
