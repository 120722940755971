/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import ActionModal from 'src/components/Modals/ActionModal';

import { Grid, Typography } from '@material-ui/core';

function DeletePackage({
  open,
  target: { id: packId },
  onClose,
  closeAfterDelete
}) {
  const [state, setState] = useState({
    submitLoading: false,
    errorMessage: ''
  });

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true,
      errorMessage: ''
    });

    try {
      await axios.delete(`/packages/${packId}`);

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterDelete();
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const { submitLoading, errorMessage } = state;
  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title="Delete Package"
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={submitLoading}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            {' '}
            Are you sure you want to delete this package?
          </Typography>
        </Grid>

        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

DeletePackage.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterDelete: PropTypes.func.isRequired
};

DeletePackage.defaultProps = {
  open: false
};

export default DeletePackage;
