/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactExport from 'react-data-export';

import { Grid, Typography } from '@material-ui/core';

import LoadingButton from 'src/components/Buttons/LoadingButton';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const tableConfig = {
  columns: [
    { title: 'Name', width: { wch: 25 } },
    { title: 'Phone', width: { wch: 15 } },
    { title: 'Id CAS', width: { wch: 15 } },
    { title: 'Serial Number', width: { wch: 15 } },
    { title: 'Start Date', width: { wch: 25 } },
    { title: 'Stop Date', width: { wch: 25 } },
    { title: 'Renew Date', width: { wch: 25 } },
    { title: 'Seller', width: { wch: 25 } }
  ]
};

const Sales = () => {
  const [state, setState] = useState({
    subscriptions: [],
    subscriptionsLoading: false,
    dataLoaded: false
  });

  const getSubscription = async () => {
    setState({
      ...state,
      subscriptionsLoading: true
    });

    const res = await axios.get('subscriptions/running');

    const subscriptions = res.data.map(sub => ({
      name: sub.smart_card.name || 'No name',
      contact_no: sub.smart_card.contact_no || 'No number',
      casId: sub.casId,
      serialNumber: sub.serialNumber,
      startDate: moment(sub.startDate).format('DD/MM/YYYY - hh:mm'),
      stopDate: moment(sub.stopDate).format('DD/MM/YYYY - hh:mm'),
      renewDate: !sub.renewDate
        ? 'No Renew'
        : moment(sub.renewDate).format('DD/MM/YYYY - hh:mm'),
      seller: sub.user.username
    }));

    setState(prevState => ({
      ...prevState,
      subscriptions,
      subscriptionsLoading: false,
      dataLoaded: true
    }));
  };

  const { subscriptions, subscriptionsLoading, dataLoaded } = state;

  const multiDataSet = [];
  const data = [];

  subscriptions.forEach(sub => {
    const row = [];
    const keys = Object.keys(sub);
    keys.forEach(key => {
      row.push({ value: sub[key] });
    });

    data.push(row);
  });

  tableConfig.data = data;

  multiDataSet.push(tableConfig);

  return (
    <>
      <Grid item xs={12}>
        <LoadingButton
          variant="contained"
          color="secondary"
          align="right"
          text={!dataLoaded ? 'Get Sales Data' : 'Refresh Sales Data'}
          loading={subscriptionsLoading}
          disabled={subscriptionsLoading}
          onClick={getSubscription}
          style={{ marginRight: '10px', marginBottom: '20px' }}
        />
        <ExcelFile
          filename={`Sales Data ${moment().format('DD-MM-YYYY')}`}
          element={
            <LoadingButton
              variant="contained"
              color="secondary"
              align="right"
              text="Export Sales"
              loading={false}
              disabled={
                !dataLoaded || subscriptionsLoading || subscriptions.length < 1
              }
              style={{ marginBottom: '20px' }}
            />
          }
        >
          <ExcelSheet dataSet={multiDataSet} name="Sales" />
        </ExcelFile>
      </Grid>
      {dataLoaded && !subscriptionsLoading && (
        <Grid item xs={12}>
          <Typography>
            Sales Count: <strong>{subscriptions.length}</strong>
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Sales;
