/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

function TableHeader({
  headCells,
  order,
  orderBy,
  rows,
  selectedRowsIds,
  handleSelectAll,
  sortHandler
}) {
  const createSortHandler = property => () => {
    sortHandler(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedRowsIds.length === rows.length}
            color="primary"
            indeterminate={
              selectedRowsIds.length > 0 && selectedRowsIds.length < rows.length
            }
            onChange={handleSelectAll}
          />
        </TableCell>

        {headCells.map(cell => (
          <TableCell key={cell.id}>
            {cell.id === 'actions' || cell.unsortable ? (
              cell.label
            ) : (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  selectedRowsIds: PropTypes.array.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired
};

export default TableHeader;
