import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import clsx from 'clsx';

import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Input,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { Check, X } from 'react-feather';

import { ADMIN } from 'src/strings/roles';

const useStyles = makeStyles(theme => ({
  root: {},
  spinner: {
    marginRight: theme.spacing(1)
  },
  setButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  syncResult,
  syncLoading,
  syncCards,
  handleSetCardsOpen,
  className,
  user
}) => {
  const classes = useStyles();

  const [isInfoOpen, setInfoOpen] = useState(false);
  const onCloseInfoDialog = () => {
    setInfoOpen(false);
    window.location.reload(false);
  };

  const [isSellerOpen, setSellerOpen] = useState(false);
  const [cardActionType, setCardActionType] = useState('Set');
  const openSellerDialog = type => {
    setCardActionType(type);
    setSellerOpen(true);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <InfoDialog
        open={isInfoOpen}
        onClose={onCloseInfoDialog}
        result={syncResult}
      />

      <SellersDialog
        open={isSellerOpen}
        onClose={() => setSellerOpen(false)}
        type={cardActionType}
      />

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {syncLoading && (
          <CircularProgress
            className={classes.spinner}
            size="25px"
            style={{ marginRight: '1rem' }}
          />
        )}

        <Button
          className={classes.setButton}
          startIcon={<X />}
          disabled={syncLoading}
          // onClick={() => openSellerDialog('Unset')}
          onClick={() => handleSetCardsOpen('unset')}
        >
          Unset cards
        </Button>

        <Button
          className={classes.setButton}
          color="primary"
          startIcon={<Check />}
          disabled={syncLoading}
          // onClick={() => openSellerDialog('Set')}
          onClick={() => handleSetCardsOpen('set')}
        >
          Set cards
        </Button>

        {user.role.code === ADMIN && (
          <Button
            color="primary"
            variant="contained"
            disabled={syncLoading}
            onClick={async () => {
              await syncCards();
              setInfoOpen(true);
            }}
          >
            Sync cards
          </Button>
        )}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  handleSetCardsOpen: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(Toolbar);

const InfoDialog = ({ onClose, open, result }) => {
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Sync completed successfully!
      </DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>
          New cards [<strong>{result?.new_cards}</strong>]
        </Typography>
        <Typography gutterBottom>
          Updated cards [<strong>{result?.updated_cards}</strong>]
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

const SellersDialog = ({ onClose, open, type }) => {
  const classes = useStyles();
  const [seller, setSeller] = useState(0);

  const [state, setState] = useState({
    sellers: [],
    loading: true
  });

  const getSubSellers = async () => {
    await setState({
      ...state,
      sellers: [],
      loading: true
    });
    try {
      const res = await axios.get(`sellers/sub-sellers/`);

      setState({
        ...state,
        sellers: res.data,
        loading: false
      });
    } catch (error) {
      console.log(error);

      setState({
        ...state,
        sellers: [],
        loading: false
      });
    }
  };

  useEffect(() => {
    if (open) getSubSellers();
  }, [open]);

  const { sellers, loading } = state;

  const handleChange = event => {
    setSeller(Number(event.target.value) || '');
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>
          {type} selected cards{' '}
          {type === String('set').toLocaleLowerCase() ? 'to' : 'from'} a seller
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="demo-dialog-native">Seller</InputLabel>
              <Select native value={seller} onChange={handleChange}>
                {loading ? (
                  <option value={0} disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value={0} disabled>
                      Select a seller
                    </option>
                    {sellers.map(seller => (
                      <option key={seller.id} value={seller.id}>
                        {seller.username}
                      </option>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} color="primary">
            {type}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
