import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, Grid, Divider, Paper } from '@material-ui/core';

import ContactList from './contactList/ContactList';
import Search from './Search';

const useStyles = makeStyles(() => ({
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  }
}));
const SideBar = ({ filterContacts }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  useEffect(() => {
    filterContacts(search);
  }, [search]);

  const onSearchChange = e => {
    setSearch(e.target.value);
  };
  const handleClose = () => {};

  return (
    <>
      <Grid item xs={3} className={classes.borderRight500}>
        <Search search={search} onChange={onSearchChange} />
        <Divider />
        {/* friends */}
        <Paper style={{ maxHeight: '450px', overflow: 'auto' }}>
          <ContactList search={search} handleClose={handleClose} />
        </Paper>
      </Grid>
    </>
  );
};

export default SideBar;
