import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  LogOut as LogOutIcon,
  MessageSquare as MessageSquareIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import store from 'src/store';
import { logout } from 'src/actions/auth';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  messagesTotalCount,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogout = () => {
    store.dispatch(logout());
  };
  const handleRedirecToMessages = () => {
    navigate('messages');
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" onClick={handleRedirecToMessages}>
            <Badge badgeContent={messagesTotalCount} max={9} color="error">
              <MessageSquareIcon />
            </Badge>
          </IconButton>
          <IconButton onClick={handleLogout} color="inherit">
            <LogOutIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  messagesTotalCount: PropTypes.number.isRequired,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
