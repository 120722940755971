/* eslint-disable space-before-function-paren */
import io from 'socket.io-client';
import {
  GET_BEIN_REQUESTS_NOTIFICATIONS,
  GET_BEIN_HISTORY_NOTIFICATIONS
} from '../actions/types';

const initialState = {
  // socketRef: io.connect('http://localhost:4000'),
  socketRef: io.connect('https://sbs.mahoustv.com'),
  beInRequestsNotificationCount: 0,
  beInHistoryNotificationCount: 0
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BEIN_REQUESTS_NOTIFICATIONS:
      return {
        ...state,
        beInRequestsNotificationCount: payload
      };
    case GET_BEIN_HISTORY_NOTIFICATIONS:
      return {
        ...state,
        beInHistoryNotificationCount: payload
      };
    default:
      return state;
  }
}
