/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import LoadingButton from 'src/components/Buttons/LoadingButton';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  TextField
} from '@material-ui/core';

function EditPackage(props) {
  const [formData, setFormData] = useState({
    label: '',
    code: ''
  });

  const [state, setState] = useState({
    submitLoading: false,
    errorField: '',
    errorFieldMessage: ''
  });

  const { label, code } = formData;
  const { submitLoading, errorField, errorFieldMessage } = state;
  const { open, onClose, closeAfterEdit } = props;

  useEffect(() => {
    const {
      target: { label, code }
    } = props;

    setFormData({
      ...formData,
      label,
      code
    });
  }, []);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true
    });

    try {
      const {
        target: { id: packageId }
      } = props;

      await axios.put(`/packages/${packageId}`, formData);

      await setState({
        ...state,
        submitLoading: false
      });

      //  Close Modal
      closeAfterEdit();
    } catch (error) {
      const {
        response: {
          data: { errorField, msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorField,
        errorFieldMessage: msg
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Add Package" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={errorField === 'label'}
                fullWidth
                label="Label"
                name="label"
                onChange={handleChange}
                required
                value={label}
                variant="outlined"
                helperText={errorField === 'label' ? errorFieldMessage : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={errorField === 'code'}
                fullWidth
                label="Code"
                name="code"
                onChange={handleChange}
                required
                value={code}
                variant="outlined"
                helperText={errorField === 'code' ? errorFieldMessage : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          align="right"
          text="Add"
          loading={submitLoading}
          disabled={submitLoading || !label || !code}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}

EditPackage.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterEdit: PropTypes.func.isRequired
};

EditPackage.defaultProps = {
  open: false
};

export default EditPackage;
