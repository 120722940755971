import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { Outlet, Navigate } from 'react-router-dom';

import { getContacts, addMessage } from 'src/actions/chat';

import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({
  isAuthenticated,
  loading,
  unreadMessages,
  socketRef,
  user,
  getContacts,
  addMessage
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  // const socketRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      await getContacts();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // socketRef = io.connect('http://localhost:4000');
      socketRef = io.connect('https://sbs.mahoustv.com');
      socketRef.on('message', messageData => {
        if (user?.id === messageData.receiverId) {
          addMessage(messageData);
        }
      });
      return () => socketRef.disconnect();
    }
  }, [isAuthenticated]);

  let messagesTotalCount = 0;
  unreadMessages.forEach(message => {
    if (message.id !== user.id) {
      messagesTotalCount += message.count;
    }
  });

  if (loading) return 'Loading';

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className={classes.root}>
      <TopBar
        messagesTotalCount={messagesTotalCount}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      <NavBar
        messagesTotalCount={messagesTotalCount}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  user: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  unreadMessages: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  socketRef: state.ui.socketRef,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  unreadMessages: state.chat.unreadMessages
});

export default connect(mapStateToProps, { getContacts, addMessage })(
  DashboardLayout
);
