/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

import { syncCards } from 'src/actions/cards';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import Action from 'src/classes/Action';

import { ADMIN } from 'src/strings/roles';

import Toolbar from './Toolbar';
import tableSettings from './tableSettings';
import SetCards from './SetCards';
import AddSubscription from './AddSubscription';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SmartCards = ({
  // cards,
  // count,
  // loading,
  syncResult,
  syncLoading,
  // getAllCards,
  syncCards,
  user
}) => {
  const classes = useStyles();

  const [state, setState] = useState({
    allCards: [],
    allCardsCount: 0,
    loading: true,
    setCardsOpen: false,
    setCardsType: '',
    selectedCards: [],
    addSubscriptionOpen: false,
    addSubscriptionTarget: null,
    tableKey: 0
  });

  const childStateGetter = data => {
    setState({ ...state, selectedCards: data.selectedRowsIds });
  };

  const handleSetCardsOpen = setCardsType => {
    setState({
      ...state,
      setCardsOpen: true,
      setCardsType
    });
  };

  const handleSetCardsClose = () => {
    setState({
      ...state,
      setCardsOpen: false,
      setCardsType: ''
    });
  };
  const handleSetCardsCloseAfterSet = () => {
    setState(prevState => ({
      ...state,
      setCardsOpen: false,
      setCardsType: '',
      tableKey: prevState.tableKey + 1
    }));
  };

  const HandleAddSubscriptionOpen = e => {
    const { allCards } = state;
    const cardId = Number(e.currentTarget.getAttribute('row-id'));
    const card = allCards.filter(item => {
      return item.id === cardId;
    })[0];

    setState({
      ...state,
      addSubscriptionOpen: true,
      addSubscriptionTarget: card
    });
  };

  const HandleAddSubscriptionClose = () => {
    setState({
      ...state,
      addSubscriptionOpen: false,
      addSubscriptionTarget: null
    });
  };
  const HandleAddSubscriptionCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      addSubscriptionOpen: false,
      addSubscriptionTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const getAllCards = async params => {
    await setState({
      ...state,
      allCards: [],
      allCardsCount: 0,
      loading: true
    });
    try {
      const res = await axios.get(`cards${params}`);

      const allCards = res.data.cards.map(card => ({
        ...card,
        canAddSubscription: true || user.role.code !== ADMIN
      }));

      setState({
        ...state,
        allCards,
        allCardsCount: res.data?.totalCards,
        loading: false
      });
    } catch (error) {
      console.log(error);

      setState({
        ...state,
        allCards: [],
        allCardsCount: 0,
        loading: false
      });
    }
  };

  const {
    tableKey,
    allCards,
    allCardsCount,
    loading,
    setCardsOpen,
    setCardsType,
    selectedCards,
    addSubscriptionOpen,
    addSubscriptionTarget
  } = state;

  // Adding actions to our table
  const actions = [];

  actions.push(
    new Action(
      'row',
      'add-subscription',
      'Add Subscription',
      'add-subscription',
      HandleAddSubscriptionOpen,
      'canAddSubscription'
    )
  );

  return (
    <Page className={classes.root} title="Smart Cards">
      <Container maxWidth={false}>
        <Toolbar
          syncResult={syncResult}
          syncLoading={syncLoading}
          syncCards={syncCards}
          handleSetCardsOpen={handleSetCardsOpen}
        />
        <Box mt={3}>
          {false ? (
            <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
          ) : (
            // <DataTable
            //   settings={tableSettings}
            //   rows={cards ? cards : []}
            //   totalrows={count}
            //   loading={loading ? 1 : 0}
            //   rowsGetter={getAllCards}
            // />

            <DataTable
              key={tableKey}
              settings={tableSettings}
              rows={allCards}
              totalrows={allCardsCount}
              loading={loading ? 1 : 0}
              limit={10}
              rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
              actions={actions}
              rowsGetter={getAllCards}
              onChange={childStateGetter}
            />
          )}
        </Box>
      </Container>

      {addSubscriptionOpen && (
        <AddSubscription
          open={addSubscriptionOpen}
          target={addSubscriptionTarget}
          onClose={HandleAddSubscriptionClose}
          closeAfterAdd={HandleAddSubscriptionCloseAfterAdd}
        />
      )}
      {setCardsOpen && (
        <SetCards
          open={setCardsOpen}
          selectedCards={selectedCards}
          type={setCardsType}
          onClose={handleSetCardsClose}
          closeAfterSet={handleSetCardsCloseAfterSet}
        />
      )}
      {setCardsOpen && (
        <SetCards
          open={setCardsOpen}
          selectedCards={selectedCards}
          type={setCardsType}
          onClose={handleSetCardsClose}
          closeAfterSet={handleSetCardsCloseAfterSet}
        />
      )}
    </Page>
  );
};

SmartCards.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  cards: state.cards.allCards,
  count: state.cards.allCardsCount,
  loading: state.cards.loading,
  syncResult: state.cards.syncResult,
  syncLoading: state.cards.syncLoading,
  error: state.cards.error
});

export default connect(mapStateToProps, {
  syncCards
})(SmartCards);
