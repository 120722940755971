/* eslint-disable max-len */
// visibilityProperty => Expects a property name string - We use this to see whether we should display the action for that row or not

export default class Action {
  constructor(place, className, tooltip, icon, func, visibilityProperty) {
    this.place = place;
    this.className = className;
    this.tooltip = tooltip;
    this.icon = icon;
    this.func = func;
    this.visibilityProperty = visibilityProperty;
  }
}
