/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Link,
  Box
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import LoadingButton from 'src/components/Buttons/LoadingButton';
import AsyncDropdown from 'src/components/Forms/AsyncDropdown';
import AlertMessage from 'src/components/Messages/AlertMessage';

const timeOptions = [
  { id: 1, label: '+10 min' },
  { id: 2, label: '+1 hour' },
  { id: 3, label: '+12 hours' },
  { id: 4, label: '+24 hours' },
  { id: 5, label: '+1 week' },
  { id: 6, label: '+1 month' },
  { id: 7, label: '+3 months' },
  { id: 8, label: '+6 months' },
  { id: 9, label: '+1 year' }
];

function AddSubscription({ open, target, onClose, closeAfterAdd }) {
  const [formData, setFormData] = useState({
    name: target.name,
    contact_no: target.contact_no,
    // contact_no: '',
    pack: 0,
    startDate: new Date(
      moment(new Date()).set({ seconds: 0, milliseconds: 0 })
    ).toISOString(),
    stopDate: null,
    renewDate: null,
    // eslint-disable-next-line max-len
    needsRenew: false // If the stopDate is 1 week or less longer than startDate, renewal date becomes mandatory
  });

  const [state, setState] = useState({
    packages: [],
    packagesLoading: true,
    submitLoading: false,
    errorField: '',
    errorFieldMessage: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      await setState({
        ...state,
        sellersLoading: true
      });

      try {
        const res = await axios.get('packages/self');

        await setState({
          ...state,
          packages: res.data,
          packagesLoading: false
        });
        setFormData({
          ...formData,
          pack: res.data[0] ? res.data[0].id : 0
        });
      } catch (error) {
        console.log(error);

        setState({
          ...state,
          sellersLoading: false
        });
      }
    };

    fetchData();
  }, []);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChangeDropdown = e => {
    const {
      target: { name }
    } = e;
    const value = Number(e.target.value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
    const newFormData = {
      ...formData,
      [name]: new Date(moment(date).set({ second: 0 })).toISOString()
    };

    // If the stopDate is 1 week or less longer than startDate, renewal date becomes mandatory
    const start = moment(newFormData.startDate);
    const stop = moment(newFormData.stopDate).subtract(1, 'seconds');
    const dayDiff = stop.diff(start, 'days');
    const needsRenew = dayDiff < 7 || false;

    setFormData({ ...newFormData, needsRenew });
  };

  const isFormValid = () => {
    const { startDate, stopDate, renewDate, needsRenew } = formData;

    if (!startDate || !stopDate) {
      return false;
    }
    const start = moment(startDate);
    const stop = moment(stopDate);

    // Stop Date must be bigger than Start Date
    let minuteDiff = stop.diff(start, 'minutes');
    if (minuteDiff < 1) {
      return false;
    }

    // If it needs a renew, Renew Date must be bigger than Stop Date

    if (needsRenew) {
      const renew = moment(renewDate);
      minuteDiff = renew.diff(stop, 'minutes');
      if (!renewDate || minuteDiff < 1) {
        return false;
      }
    }

    return true;
  };

  const handleDatePresetStopDate = id => {
    const { startDate } = formData;

    switch (id) {
      case 1: // After +10 min
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(10, 'minutes')
            .set({ second: 0 })
        );
        break;
      case 2: // After 1 hour
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(1, 'hours')
            .set({ second: 0 })
        );
        break;
      case 3: // After 12 hours
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(12, 'hours')
            .set({ second: 0 })
        );
        break;
      case 4: // After 24 hours
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(24, 'hours')
            .set({ second: 0 })
        );
        break;
      case 5: // After a week
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(1, 'weeks')
            .set({ second: 0 })
        );
        break;
      case 6: // After a month
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(1, 'months')
            .set({ second: 0 })
        );
        break;
      case 7: // After 3 months
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(3, 'months')
            .set({ second: 0 })
        );
        break;
      case 8: // After 6 months
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(6, 'months')
            .set({ second: 0 })
        );
        break;
      case 9: // After a year
        handleDateChange(
          'stopDate',
          moment(startDate)
            .add(1, 'years')
            .set({ second: 0 })
        );
        break;

      default:
        break;
    }
  };

  const handleDatePresetRenewDate = id => {
    const { startDate } = formData;

    switch (id) {
      case 1: // After +10 min
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(10, 'minutes')
            .set({ second: 0 })
        );
        break;
      case 2: // After 1 hour
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(1, 'hours')
            .set({ second: 0 })
        );
        break;
      case 3: // After 12 hours
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(12, 'hours')
            .set({ second: 0 })
        );
        break;
      case 4: // After 24 hours
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(24, 'hours')
            .set({ second: 0 })
        );
        break;
      case 5: // After a week
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(1, 'weeks')
            .set({ second: 0 })
        );
        break;
      case 6: // After a month
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(1, 'months')
            .set({ second: 0 })
        );
        break;
      case 7: // After 3 months
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(3, 'months')
            .set({ second: 0 })
        );
        break;
      case 8: // After 6 months
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(6, 'months')
            .set({ second: 0 })
        );
        break;
      case 9: // After a year
        handleDateChange(
          'renewDate',
          moment(startDate)
            .add(1, 'years')
            .set({ second: 0 })
        );
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true,
      errorField: '',
      errorFieldMessage: ''
    });

    const { pack } = formData;
    const { packages } = state;

    try {
      let _formData = {
        ...formData,
        package: packages.find(element => element.id === pack).code,
        serialNumber: target.serial_no
      };

      await axios.post('/subscriptions', _formData);

      await setState({
        ...state,
        submitLoading: false
      });

      //  Close Modal
      closeAfterAdd();
    } catch (error) {
      const {
        response: {
          data: { errorField, msg }
        }
      } = error;
      if (errorField) {
        setState({
          ...state,
          submitLoading: false,
          errorField,
          errorFieldMessage: msg
        });
      } else {
        setState({
          ...state,
          submitLoading: false,
          errorFieldMessage: msg
        });
      }
    }
  };

  const {
    packages,
    packagesLoading,
    submitLoading,
    errorField,
    errorFieldMessage
  } = state;
  const {
    name,
    contact_no,
    pack,

    startDate,
    stopDate,
    renewDate,
    needsRenew
  } = formData;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Add Subscription" />
        <Divider />
        <CardContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{`Card Serial No #${target.serial_no} (${target.name})`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errorField === 'name'}
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={name}
                  variant="outlined"
                  helperText={errorField === 'name' ? errorFieldMessage : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={errorField === 'contact_no'}
                  fullWidth
                  label="Phone"
                  name="contact_no"
                  onChange={handleChange}
                  value={contact_no}
                  variant="outlined"
                  helperText={
                    errorField === 'contact_no' ? errorFieldMessage : ''
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <AsyncDropdown
                  label="Package*"
                  name="pack"
                  optionLabel="label"
                  placeholder="Select a package"
                  options={packages}
                  value={pack}
                  loading={packagesLoading}
                  handleChange={handleChangeDropdown}
                />
              </Grid>

              <Grid item xs={12}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  // locale={localeMap[currentLocale]}
                >
                  <DateTimePicker
                    disablePast
                    label="Starting Date*"
                    format="MM/dd/yyyy - HH:mm"
                    variant="inline"
                    value={startDate}
                    onChange={date => handleDateChange('startDate', date)}
                    style={{
                      width: '100%'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} container>
                <Grid item sm={3} xs={12}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    // locale={localeMap[currentLocale]}
                  >
                    <DateTimePicker
                      disablePast
                      label="Expiration Date*"
                      format="MM/dd/yyyy - HH:mm"
                      variant="inline"
                      value={stopDate}
                      onChange={date => handleDateChange('stopDate', date)}
                      style={{
                        width: '100%'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    p={1}
                    m={1}
                    bgcolor="background.paper"
                    overflow="auto"
                    sx={{ height: 100 }}
                  >
                    {timeOptions.map(option => (
                      <Box
                        key={option.id}
                        px={2}
                        borderLeft={option.id === 1 ? 0 : 1}
                        textAlign="left"
                      >
                        <Link
                          href="#"
                          py={3}
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => handleDatePresetStopDate(option.id)}
                        >
                          {option.label}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>

              {needsRenew && (
                <Grid item xs={12} container>
                  <Grid item sm={3} xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      // locale={localeMap[currentLocale]}
                    >
                      <DateTimePicker
                        disablePast
                        label="Renew Stop Date*"
                        format="MM/dd/yyyy - HH:mm"
                        variant="inline"
                        value={renewDate}
                        onChange={date => handleDateChange('renewDate', date)}
                        style={{
                          width: '100%'
                        }}
                        helperText="Renew Stop Date must be bigger than the Expiration Date"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item sm={9} xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      m={1}
                      bgcolor="background.paper"
                      overflow="auto"
                      sx={{ height: 100 }}
                    >
                      {timeOptions.map(option => {
                        if (option.id < 6) {
                          return false;
                        }
                        return (
                          <Box
                            key={option.id}
                            px={2}
                            borderLeft={option.id === 6 ? 0 : 1}
                            textAlign="left"
                          >
                            <Link
                              href="#"
                              py={3}
                              style={{ whiteSpace: 'nowrap' }}
                              onClick={
                                () =>
                                  // eslint-disable-next-line implicit-arrow-linebreak
                                  handleDatePresetRenewDate(option.id)
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              {option.label}
                            </Link>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {errorFieldMessage && !errorField && (
                <Grid item xs={12}>
                  <AlertMessage message={errorFieldMessage} />
                </Grid>
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </CardContent>
      </Card>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          align="right"
          text="Add"
          loading={submitLoading}
          disabled={submitLoading || !pack || !isFormValid()}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}

AddSubscription.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterAdd: PropTypes.func.isRequired
};

AddSubscription.defaultProps = {
  open: false
};

export default AddSubscription;
