/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import Action from 'src/classes/Action';

import Toolbar from './Toolbar';
import AddPackage from './AddPackage';
import EditPackage from './EditPackage';
import DeletePackage from './DeletePackage';
import tableSettings from './tableSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BeinPackagesView = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    packages: [],
    totalPackages: 0,
    loading: true,
    addPackageOpen: false,
    editPackageOpen: false,
    editPackageTarget: null,
    deletePackageOpen: false,
    deletePackageTarget: null,
    tableKey: 0,
    // We use this to redirect the page to the specific pack when clicking on Package's Cards
    redirect: false,
    redirectTarget: 0
  });

  const handleAddPackageOpen = () => {
    setState({
      ...state,
      addPackageOpen: true
    });
  };
  const handleAddPackageClose = () => {
    setState({
      ...state,
      addPackageOpen: false
    });
  };
  const handleAddPackageCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      addPackageOpen: false,
      tableKey: prevState.tableKey + 1
    }));
  };

  const handleEditPackageOpen = e => {
    const { packages } = state;
    const packageId = Number(e.currentTarget.getAttribute('row-id'));
    const pack = packages.filter(item => {
      return item.id === packageId;
    })[0];

    setState({
      ...state,
      editPackageOpen: true,
      editPackageTarget: pack
    });
  };

  const handleEditPackageClose = () => {
    setState({
      ...state,
      editPackageOpen: false,
      editPackageTarget: null
    });
  };

  const handleEditPackageCloseAfterEdit = () => {
    setState(prevState => ({
      ...state,
      editPackageOpen: false,
      editPackageTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const HandleDeletePackage = e => {
    const { packages } = state;
    const packageId = Number(e.currentTarget.getAttribute('row-id'));
    const pack = packages.filter(item => {
      return item.id === packageId;
    })[0];

    setState({
      ...state,
      deletePackageOpen: true,
      deletePackageTarget: pack
    });
  };

  const handleDeletePackageClose = () => {
    setState({
      ...state,
      deletePackageOpen: false,
      deletePackageTarget: null
    });
  };

  const handleDeletePackageCloseAfterDelete = () => {
    setState(prevState => ({
      ...state,
      deletePackageOpen: false,
      deletePackageTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const GetPackages = async params => {
    await setState({
      ...state,
      loading: true
    });

    const res = await axios.get(`beinPackages${params}`);

    // Mapping the data for the data table
    const packages = res.data.packages.map(pack => ({
      ...pack,
      createdAt: moment(pack.createdAt).format('DD/MM/YYYY - hh:mm')
    }));

    setState({
      ...state,
      packages,
      totalPackages: res.data.totalPackages,
      loading: false
    });
  };

  const {
    packages,
    totalPackages,
    loading,
    tableKey,
    addPackageOpen,
    editPackageOpen,
    editPackageTarget,
    deletePackageOpen,
    deletePackageTarget
  } = state;

  // #region Actions

  // Adding actions to our table
  const actions = [];
  // Head
  // actions.push(new Action('head', 'refresh', 'Cards', 'refresh', HandleTest));
  // Row
  actions.push(
    new Action('row', 'edit-pack', 'Edit', 'edit', handleEditPackageOpen)
  );
  actions.push(
    new Action('row', 'delete-pack', 'Delete', 'delete', HandleDeletePackage)
  );

  // #endregion Actions

  return (
    <Page className={classes.root} title="Packages">
      <Container maxWidth={false}>
        <Toolbar handleAddPackageOpen={handleAddPackageOpen} />
        <Box mt={3}>
          <DataTable
            key={tableKey}
            rows={packages}
            totalrows={totalPackages}
            loading={loading ? 1 : 0}
            actions={actions}
            settings={tableSettings}
            rowsGetter={GetPackages}
            rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
          />
        </Box>
        {addPackageOpen && (
          <AddPackage
            open={addPackageOpen}
            onClose={handleAddPackageClose}
            closeAfterAdd={handleAddPackageCloseAfterAdd}
          />
        )}
        {editPackageOpen && (
          <EditPackage
            open={editPackageOpen}
            target={editPackageTarget}
            onClose={handleEditPackageClose}
            closeAfterEdit={handleEditPackageCloseAfterEdit}
          />
        )}
        {deletePackageOpen && (
          <DeletePackage
            open={deletePackageOpen}
            target={deletePackageTarget}
            onClose={handleDeletePackageClose}
            closeAfterDelete={handleDeletePackageCloseAfterDelete}
          />
        )}
      </Container>
    </Page>
  );
};

export default BeinPackagesView;
