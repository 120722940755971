module.exports = {
  headCells: [
    { id: 'id', label: 'Id' },
    { id: 'serial_no', label: 'Serial No' },
    { id: 'status', label: 'Status' },
    { id: 'name', label: 'Name' },
    { id: 'contract_no', label: 'Contact No' },
    { id: 'address', label: 'Address' },
    { id: 'info', label: 'Info' },
    { id: 'actions', label: '' }
  ]
};
