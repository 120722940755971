/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import PropTypes from 'prop-types';

import { Alert, AlertTitle } from '@material-ui/lab';

function AlertMessage(props) {
  const { title, type, message } = props;
  return (
    <Alert severity={type}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}

AlertMessage.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  message: PropTypes.string.isRequired
};

AlertMessage.defaultProps = {
  title: 'Error',
  type: 'error'
};

export default AlertMessage;
