import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import store from 'src/store';
import { getBeinHistoryCount } from 'src/actions/ui';
import { ADMIN } from 'src/strings/roles';
import Action from 'src/classes/Action';

import DeleteHistory from './DeleteHistory';
import tableSettings from './tableSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BeinSubscriptionsView = ({ user }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    subscriptions: [],
    totalSubscriptions: 0,
    deleteHistoryOpen: false,
    deleteHistoryTarget: null,
    loading: true,
    tableKey: 0
  });
  const isAdmin = user.role.code === ADMIN;

  const GetSubscriptions = async params => {
    await setState({
      ...state,
      loading: true
    });
    const res = await axios.get(`beinPackagesHistory${params}`);

    // Refreshing Requests Notifcation Count
    if (!isAdmin) store.dispatch(getBeinHistoryCount());

    // Mapping the data for the data table
    const subscriptions = res.data.subscriptions.map(sub => ({
      ...sub,
      seller: sub.user.username,
      createdAt: moment(sub.createdAt).format('DD/MM/YYYY - hh:mm')
    }));

    setState({
      ...state,
      subscriptions,
      totalSubscriptions: res.data.totalSubscriptions,
      loading: false
    });
  };

  const HandleDeleteHistory = e => {
    const { subscriptions } = state;
    const subscriptionId = Number(e.currentTarget.getAttribute('row-id'));
    const sub = subscriptions.filter(item => {
      return item.id === subscriptionId;
    })[0];

    setState({
      ...state,
      deleteHistoryOpen: true,
      deleteHistoryTarget: sub
    });
  };

  const handleDeleteHistoryClose = () => {
    setState({
      ...state,
      deleteHistoryOpen: false,
      deleteHistoryTarget: null
    });
  };

  const handleDeleteHistoryCloseAfterDelete = () => {
    setState(prevState => ({
      ...state,
      deleteHistoryOpen: false,
      deleteHistoryTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const {
    subscriptions,
    totalSubscriptions,
    loading,
    deleteHistoryOpen,
    deleteHistoryTarget,
    tableKey
  } = state;

  const actions = [];

  if (isAdmin) {
    actions.push(
      new Action(
        'row',
        'delete-history',
        'Delete',
        'delete',
        HandleDeleteHistory
      )
    );
  }

  return (
    <Page className={classes.root} title="BeIN Subscriptions History">
      <Container maxWidth={false}>
        <Box mt={3}>
          {' '}
          <DataTable
            key={tableKey}
            rows={subscriptions}
            totalrows={totalSubscriptions}
            loading={loading ? 1 : 0}
            actions={actions}
            settings={tableSettings}
            rowsGetter={GetSubscriptions}
            rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
          />
        </Box>
        {deleteHistoryOpen && (
          <DeleteHistory
            open={deleteHistoryOpen}
            target={deleteHistoryTarget}
            onClose={handleDeleteHistoryClose}
            closeAfterDelete={handleDeleteHistoryCloseAfterDelete}
          />
        )}
      </Container>
    </Page>
  );
};

BeinSubscriptionsView.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(BeinSubscriptionsView);
