/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Grid, Typography } from '@material-ui/core';

import ActionModal from 'src/components/Modals/ActionModal';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import AlertMessage from 'src/components/Messages/AlertMessage';

function StopSubscription({
  open,
  target: { id: subId, casId },
  closeAfterStop,
  onClose
}) {
  const [state, setState] = useState({
    submitLoading: false,
    errorMessage: ''
  });

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true
    });

    try {
      await axios.get(`/subscriptions/remove/${subId}`);

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterStop();
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const { submitLoading, errorMessage } = state;

  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title="Stop Subscription"
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={submitLoading}
            onClick={handleSubmit}
          />
        </>
      }
    >
      {' '}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            {' '}
            Do you really want to stop this subscription with the CAS ID
            <strong> {casId}</strong>?
          </Typography>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

StopSubscription.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterStop: PropTypes.func.isRequired
};

StopSubscription.defaultProps = {
  open: false
};

export default StopSubscription;
