/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  // Checkbox,
  Table,
  TableBody,
  makeStyles
} from '@material-ui/core';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import TablePagination from './TablePagination';
import Toolbar from './TableHeader/Toolbar';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const DataTable = ({
  className,
  rows,
  totalrows,
  loading,
  settings,
  actions,
  rowsGetter,
  limit: limitProps,
  rowsPerPageOptions,
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: limitProps || 10
  });
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [state, setState] = useState({
    isMounted: false
  });

  const { page, limit } = pagination;

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalrows / limit); i += 1) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const { isMounted } = state;
    if (!isMounted) {
      rowsGetter(
        `/${page}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
      );
      setState({ ...state, isMounted: true });
    }

    onChange({ selectedRowsIds });
  }, [selectedRowsIds]);

  const handleRefresh = () => {
    rowsGetter(
      `/${page}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
  };

  const handleSelectAll = event => {
    let newSelectedRowsIds;

    if (event.target.checked) {
      newSelectedRowsIds = rows.map(customer => customer.id);
    } else {
      newSelectedRowsIds = [];
    }

    setSelectedRowsIds(newSelectedRowsIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRowsIds.indexOf(id);
    let newSelectedRowsIds = [];

    if (selectedIndex === -1) {
      newSelectedRowsIds = newSelectedRowsIds.concat(selectedRowsIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRowsIds = newSelectedRowsIds.concat(selectedRowsIds.slice(1));
    } else if (selectedIndex === selectedRowsIds.length - 1) {
      newSelectedRowsIds = newSelectedRowsIds.concat(
        selectedRowsIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedRowsIds = newSelectedRowsIds.concat(
        selectedRowsIds.slice(0, selectedIndex),
        selectedRowsIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(newSelectedRowsIds);
  };

  const sortHandler = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    rowsGetter(
      `/${page}/${limit}?search=${search}&order=${
        isAsc ? 'desc' : 'asc'
      }&orderBy=${property}`
    );
  };
  const handleLimitChange = event => {
    const limit = parseInt(event.target.value, 10);
    const page = 1;
    rowsGetter(
      `/${page}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
    setPagination({ ...pagination, page, limit });
  };

  const handleSearchChange = event => {
    const search = event.target.value;
    rowsGetter(
      `/${page}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
    setSearch(search);
  };

  const handlePageChange = newPage => {
    const page = newPage;
    rowsGetter(
      `/${page}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
    setPagination({ ...pagination, page });
  };

  const onPrev = () => {
    if (page > 1) {
      setPagination({ ...pagination, page: page - 1 });
      rowsGetter(
        `/${page -
          1}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
      );
    } else {
      setPagination({ ...pagination, page: 1 });
      rowsGetter(
        `/${1}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
      );
    }
  };

  const onNext = totalPages => {
    if (page < totalPages) {
      setPagination({ ...pagination, page: page + 1 });
      rowsGetter(
        `/${page +
          1}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
      );
    } else {
      setPagination({ ...pagination, totalPages });
      rowsGetter(
        `/${totalPages}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
      );
    }
  };

  const onGoFirst = () => {
    setPagination({ ...pagination, page: 1 });
    rowsGetter(
      `/${1}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
  };

  const onGoLast = totalPages => {
    setPagination({ ...pagination, page: totalPages });
    rowsGetter(
      `/${totalPages}/${limit}?search=${search}&order=${order}&orderBy=${orderBy}`
    );
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Toolbar
        actions={actions}
        search={search}
        handleRefresh={handleRefresh}
        handleSearchChange={handleSearchChange}
      />
      {!loading && (
        <TablePagination
          curpage={pageNumbers.length <= 1 ? 1 : page}
          totpages={pageNumbers.length <= 1 ? 1 : pageNumbers.length}
          totalRows={totalrows}
          rowsCount={rows.length}
          rowsPerPage={limit}
          handleChangeRowsPerPage={handleLimitChange}
          onChange={handlePageChange}
          siblingPagesRange={1}
          onPrev={onPrev}
          onNext={() => onNext(pageNumbers.length)}
          onGoFirst={onGoFirst}
          onGoLast={() => onGoLast(pageNumbers.length)}
        />
      )}
      <Box minWidth={1050}>
        <Table>
          <TableHeader
            rows={rows}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
            actions={actions}
            headCells={settings.headCells}
            selectedRowsIds={selectedRowsIds}
            handleSelectAll={handleSelectAll}
          />
          <TableBody>
            <TableRows
              headCells={settings.headCells}
              rows={rows}
              loading={loading}
              actions={actions}
              selectedRowsIds={selectedRowsIds}
              setSelectedRowsIds={setSelectedRowsIds}
              handleSelectOne={handleSelectOne}
            />
          </TableBody>
        </Table>
      </Box>
      {!loading && (
        <TablePagination
          curpage={pageNumbers.length <= 1 ? 1 : page}
          totpages={pageNumbers.length <= 1 ? 1 : pageNumbers.length}
          totalRows={totalrows}
          rowsCount={rows.length}
          rowsPerPage={limit}
          handleChangeRowsPerPage={handleLimitChange}
          onChange={handlePageChange}
          siblingPagesRange={1}
          onPrev={onPrev}
          onNext={() => onNext(pageNumbers.length)}
          onGoFirst={onGoFirst}
          onGoLast={() => onGoLast(pageNumbers.length)}
        />
      )}
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array.isRequired,
  totalrows: PropTypes.number.isRequired,
  settings: PropTypes.object.isRequired,
  loading: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  rowsGetter: PropTypes.func.isRequired,
  limit: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  onChange: PropTypes.func
};

export default DataTable;
