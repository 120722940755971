/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  user: {
    role: { code: roleCode }
  },
  isAuthenticated,
  loading,
  access,
  ...rest
}) =>
  !isAuthenticated && !loading ? (
    <Navigate to="/login" />
  ) : access.length > 0 && !access.includes(roleCode) ? (
    <Navigate to="/dashboard/unauthorized" />
  ) : (
    <Component />
  );
// }) => (
//   <Route
//     {...rest}
//     render={props =>
//       !isAuthenticated && !loading ? (
//         <Navigate to="/login" />
//       ) : access.length > 0 && !access.includes(roleCode) ? (
//         <Navigate to="/not-authorized" />
//       ) : (
//         <Component {...props} />
//       )
//     }
//   />
// );

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  access: PropTypes.array
};

PrivateRoute.defaultProps = {
  access: []
};

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
});

export default connect(mapStateToProps)(PrivateRoute);
