import { combineReducers } from 'redux';
import auth from './auth';
import ui from './ui';
import chat from './chat';
import cards from './cards';

export default combineReducers({
  ui,
  auth,
  chat,
  cards
});
