/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Grid, Typography } from '@material-ui/core';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import ActionModal from 'src/components/Modals/ActionModal';

function SetCards(props) {
  const [state, setState] = useState({
    submitLoading: false,
    errorMessage: ''
  });

  const { open, onClose, closeAfterSet, selectedCards } = props;

  const handleSubmit = async () => {
    const {
      selectedCards,
      target: { id: seller_id }
    } = props;

    await setState({
      ...state,
      submitLoading: true
    });

    let _formData = {
      cards: selectedCards,
      seller_id
    };

    try {
      await axios.post('/cards/unset/batch', _formData);

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterSet();

      // Close Modal
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const { target } = props;
  const { errorMessage, submitLoading } = state;

  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title="Unset Selected Cards"
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={submitLoading}
            onClick={handleSubmit}
          />
        </>
      }
    >
      {' '}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            {' '}
            Do you want to unset the selected cards for &quot;{target.username}
            &quot;? (Total selected cards:
            <strong>{selectedCards.length}</strong>)
          </Typography>
        </Grid>

        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

SetCards.propTypes = {
  open: PropTypes.bool,
  selectedCards: PropTypes.array.isRequired,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterSet: PropTypes.func.isRequired
};

SetCards.defaultProps = {
  open: false
};

export default SetCards;
