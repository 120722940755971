import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useRef, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import axios from 'axios';
import theme from 'src/theme';
import routes from 'src/routes';
import setAuthToken from 'src/utils/setAuthToken';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { LOGOUT } from './actions/types';

import './App.css';

// Setting Axios' base settings
// axios.defaults.baseURL = 'http://localhost:4000/rest';
axios.defaults.baseURL = 'https://sbs.mahoustv.com/rest';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const App = () => {
  const routing = useRoutes(routes);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());
    setLoading(false);

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  useInterval(() => {
    store.dispatch(loadUser());
  }, 30 * 1000);

  return (
    <Provider store={store}>
      {loading ? (
        <>Loading... </>
      ) : (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      )}
    </Provider>
  );
};

export default App;
