/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-fragments */
/* eslint-disable nonblock-statement-body-position */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, TableCell, TableRow } from '@material-ui/core';

import RowAction from './RowAction';

function TableRowItem({
  row,
  headCells,
  selectedRowsIds,
  actions,
  handleSelectOne
}) {
  return (
    <TableRow
      hover
      key={row.id}
      selected={selectedRowsIds.indexOf(row.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRowsIds.indexOf(row.id) !== -1}
          onChange={event => handleSelectOne(event, row.id)}
          value="true"
        />
      </TableCell>
      {/*       {headCells.map(cell => {
        if (cell.id === 'actions')
          return <TableCell key={cell.id}>{row[cell.id]}</TableCell>;
        return false;
      })} */}

      {headCells.map((cell, index) => (
        <TableCell key={index} align="left">
          <Fragment>
            {' '}
            {cell.id === 'actions'
              ? actions
                  .filter(cell => cell.place === 'row')
                  .map((action, index) => (
                    <RowAction
                      key={index}
                      id={row.id}
                      className={action.className}
                      tooltip={action.tooltip}
                      icon={action.icon}
                      func={action.func}
                      isVisible={
                        action.visibilityProperty
                          ? row[action.visibilityProperty]
                          : true
                      }
                    />
                    // eslint-disable-next-line indent
                  ))
              : row[cell.id]}
          </Fragment>
        </TableCell>
      ))}

      {/* <TableCell>
        <RowAction tooltip="Test" icon={CreditCard} />
      </TableCell> */}
    </TableRow>
  );
}

TableRowItem.propTypes = {
  headCells: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  selectedRowsIds: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  handleSelectOne: PropTypes.func.isRequired
};

export default TableRowItem;
