// Auth
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// UI
export const GET_BEIN_REQUESTS_NOTIFICATIONS =
  'GET_BEIN_REQUESTS_NOTIFICATIONS';
export const GET_BEIN_HISTORY_NOTIFICATIONS = 'GET_BEIN_HISTORY_NOTIFICATIONS';

// Chat
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_OLDER_MESSAGES = 'GET_OLDER_MESSAGES';
export const SET_OLDER_MESSAGES_LOADING = 'SET_OLDER_MESSAGES_LOADING';
export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const SET_CONTACT_LIST_LOADING = 'SET_CONTACT_LIST_LOADING';
export const UNSET_CONTACT_LIST_LOADING = 'UNSET_CONTACT_LIST_LOADING';
export const FILTER_CONTACT_LIST = 'FILTER_CONTACT_LIST';
export const STOP_FILTERING_CONTACT_LIST = 'STOP_FILTERING_CONTACT_LIST';
export const UNSET_CONTACT = 'UNSET_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';
export const READ_MESSAGE = 'READ_MESSAGE';
export const READ_MESSAGES = 'READ_MESSAGES';

// Cards
export const GET_ALL_CARDS = 'GET_ALL_CARDS';
export const SYNC_CARDS = 'SYNC_CARDS';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_SYNC_CARDS = 'SET_LOADING_SYNC_CARDS';
export const CARDS_ERROR = 'CARDS_ERROR';
export const SYNC_CARDS_ERROR = 'SYNC_CARDS_ERROR';
