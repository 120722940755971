/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import ActionModal from 'src/components/Modals/ActionModal';

import { Grid, Typography, TextField } from '@material-ui/core';

function Edit({
  open,
  target: { id: sellerId, username, credit: currentCredit },
  onClose,
  closeAfterEdit
}) {
  const [state, setState] = useState({
    credit: 0,
    submitLoading: false,
    errorMessage: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;

    const newValue = Number(value) + currentCredit < 0 ? -currentCredit : value;

    setState({ ...state, [name]: Number(newValue) });
  };

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true,
      errorMessage: ''
    });

    const { credit } = state;

    try {
      await axios.put(`/sellers/credit/${sellerId}`, {
        credit
      });

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterEdit();
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const { credit, submitLoading, errorMessage } = state;

  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title={`Edit ${username} Credit`}
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={submitLoading || !credit || credit === 0}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            Current credit: <strong>{currentCredit}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            // error={errorField === 'username'}
            type="number"
            fullWidth
            label="Credit (Increase/Decrease)"
            name="credit"
            onChange={handleChange}
            required
            value={credit}
            variant="outlined"
            helperText={errorMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>
            New credit: <strong>{credit + currentCredit}</strong>
          </Typography>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

Edit.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterEdit: PropTypes.func.isRequired
};

Edit.defaultProps = {
  open: false
};

export default Edit;
