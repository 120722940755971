import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function CircularLoading() {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justify="center" direction="row">
      <CircularProgress color="secondary" />
    </Grid>
  );
}
