/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import LoadingButton from 'src/components/Buttons/LoadingButton';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  TextField,
  Typography
} from '@material-ui/core';

function AddPackage(props) {
  const [formData, setFormData] = useState({
    label: '',
    price: 0
  });

  const [state, setState] = useState({
    submitLoading: false,
    errorField: '',
    errorFieldMessage: ''
  });

  const { label, price } = formData;
  const { submitLoading, errorField, errorFieldMessage } = state;
  const { open, onClose, closeAfterAdd } = props;

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true
    });

    try {
      await axios.post('/beinPackages', formData);

      await setState({
        ...state,
        submitLoading: false
      });

      //  Close Modal
      closeAfterAdd();
    } catch (error) {
      const {
        response: {
          data: { errorField, msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorField,
        errorFieldMessage: msg
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Add Package" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={errorField === 'label'}
                fullWidth
                label="Label"
                name="label"
                onChange={handleChange}
                required
                value={label}
                variant="outlined"
                helperText={errorField === 'label' ? errorFieldMessage : ''}
              />
              <Typography variant="caption">
                This field is used for package display name
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={errorField === 'price'}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                label="Price"
                name="price"
                onChange={handleChange}
                required
                value={price}
                variant="outlined"
                helperText={errorField === 'price' ? errorFieldMessage : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          align="right"
          text="Add"
          loading={submitLoading}
          disabled={submitLoading || !label || price < 0}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}

AddPackage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  closeAfterAdd: PropTypes.func.isRequired
};

AddPackage.defaultProps = {
  open: false
};

export default AddPackage;
