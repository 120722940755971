import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { useState } from 'react';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm: {
      display: 'flex',
      justifyContent: 'center',
      width: '95%',
      margin: `${theme.spacing(0)} auto`
    },
    wrapText: {
      width: '100%'
    },
    button: {
      // margin: theme.spacing(1),
    }
  })
);

const AreaTypeMessage = ({ user, contact, sendMessage, seenMessage }) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [seen, setSeen] = useState(false);

  // text input
  const handlMessage = e => {
    setMessage(e.target.value);
  };
  // send message
  const handleSendMessage = e => {
    e.preventDefault();
    sendMessage({
      id: 3,
      content: message,
      senderId: user.id,
      receiverId: contact,
      isRead: false,
      timeStamp: moment().format()
    });
    setMessage('');
  };
  // seen message
  const handleSeen = e => {
    e.preventDefault();
    setSeen(true);
    seenMessage(seen);
  };

  return (
    <>
      <Grid container style={{ padding: '20px' }}>
        <form className={classes.wrapForm} noValidate autoComplete="off">
          <TextField
            id="standard-text"
            label="Type Something"
            className={classes.wrapText}
            //margin="normal"
            value={message}
            onChange={handlMessage}
            onClick={handleSeen}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSendMessage}
          >
            <SendIcon />
          </Button>
        </form>
      </Grid>
    </>
  );
};

export default AreaTypeMessage;
