/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import ActionModal from 'src/components/Modals/ActionModal';
import AsyncDropdown from 'src/components/Forms/AsyncDropdown';

function SetCards(props) {
  const [state, setState] = useState({
    sellers: [],
    seller: 0,
    setActive: true,
    sellersLoading: true,
    submitLoading: false,
    errorMessage: ''
  });

  const { open, selectedCards, type, closeAfterSet, onClose } = props;

  useEffect(() => {
    const fetchData = async () => {
      await setState({
        ...state,
        sellersLoading: true
      });

      try {
        const res = await axios.get('sellers/sub-sellers/');

        setState({
          ...state,
          sellers: res.data,
          sellersLoading: false
        });
      } catch (error) {
        console.log(error);

        setState({
          ...state,
          sellersLoading: false
        });
      }
    };

    fetchData();
  }, []);

  const handleChange = event => {
    const seller = Number(event.target.value);
    setState(state => ({
      ...state,
      seller
    }));
  };
  const handleCheckbox = event => {
    const { name } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const handleSubmit = async () => {
    const { selectedCards } = props;
    const { seller: seller_id, setActive } = state;
    await setState({
      ...state,
      submitLoading: true
    });

    let _formData = {
      cards: selectedCards,
      seller_id,
      setActive
    };

    try {
      await axios.post(`/cards/${type}/batch`, _formData);

      await setState({
        ...state,
        submitLoading: false
      });

      // Close Modal
      closeAfterSet();
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const {
    sellers,
    seller,
    setActive,
    sellersLoading,
    errorMessage,
    submitLoading
  } = state;

  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title={`${type === 'set' ? 'Set' : 'Unset'} Selected Cards`}
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text={type === 'set' ? 'Set' : 'Unset'}
            loading={submitLoading}
            disabled={submitLoading || selectedCards.length < 1 || seller === 0}
            onClick={handleSubmit}
          />
        </>
      }
    >
      {' '}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            {' '}
            Do you want to {type} the selected cards for this seller? (Total
            selected cards:
            <strong>{selectedCards.length}</strong>)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AsyncDropdown
            label="Seller"
            name="seller"
            optionLabel="username"
            placeholder="Select a seller"
            options={sellers}
            value={seller}
            loading={sellersLoading}
            handleChange={handleChange}
          />
        </Grid>
        {type === 'set' && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setActive}
                  onChange={handleCheckbox}
                  name="setActive"
                />
              }
              label="Activate selected cards"
            />
          </Grid>
        )}
        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

SetCards.propTypes = {
  open: PropTypes.bool,
  selectedCards: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['set', 'unset']),
  onClose: PropTypes.func.isRequired,
  closeAfterSet: PropTypes.func.isRequired
};

SetCards.defaultProps = {
  open: false
};

export default SetCards;
