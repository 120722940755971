import React from 'react';

// import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';

import SellersView from './SellersView';
import SellerCards from './SellerCards';

const routes = [
  {
    path: '/',
    children: [
      { path: ':id', element: <SellerCards /> },
      { path: '/', element: <SellersView /> }
    ]
  }
];

function SellersRoute() {
  const routing = useRoutes(routes);
  return <>{routing}</>;
}

export default SellersRoute;
