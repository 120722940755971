import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import Action from 'src/classes/Action';
import { ADMIN } from 'src/strings/roles';
import store from 'src/store';
import { getBeinRequestsCount } from 'src/actions/ui';

import Toolbar from './Toolbar';
import RequestSubscription from './RequestSubscription';
import AcceptSubscription from './AcceptSubscription';
import RejectSubscription from './RejectSubscription';
import tableSettings from './tableSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BeinSubscriptionsRequestsView = ({ user }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    subscriptions: [],
    totalSubscriptions: 0,
    loading: true,
    requestSubscriptionOpen: false,
    acceptSubscriptionOpen: false,
    acceptSubscriptionTarget: null,
    rejectSubscriptionOpen: false,
    rejectSubscriptionTarget: null,
    tableKey: 0
  });
  const isAdmin = user.role.code === ADMIN;

  // Request Subscription events
  const handleRequestSubscriptionOpen = () => {
    setState({
      ...state,
      requestSubscriptionOpen: true
    });
  };
  const handleRequestSubscriptionClose = () => {
    setState({
      ...state,
      requestSubscriptionOpen: false
    });
  };
  const handleRequestSubscriptionCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      requestSubscriptionOpen: false,
      tableKey: prevState.tableKey + 1
    }));
  };

  // Accept Subscription events
  const handleAcceptSubscriptionOpen = e => {
    const { subscriptions } = state;
    const subscriptionId = Number(e.currentTarget.getAttribute('row-id'));
    const subscription = subscriptions.filter(item => {
      return item.id === subscriptionId;
    })[0];

    setState({
      ...state,
      acceptSubscriptionOpen: true,
      acceptSubscriptionTarget: subscription
    });
  };
  const handleAcceptSubscriptionClose = () => {
    setState({
      ...state,
      acceptSubscriptionOpen: false,
      acceptSubscriptionTarget: null
    });
  };
  const handleAcceptSubscriptionCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      acceptSubscriptionOpen: false,
      acceptSubscriptionTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  // Reject Subscription events
  const handleRejectSubscriptionOpen = e => {
    const { subscriptions } = state;
    const subscriptionId = Number(e.currentTarget.getAttribute('row-id'));
    const subscription = subscriptions.filter(item => {
      return item.id === subscriptionId;
    })[0];

    setState({
      ...state,
      rejectSubscriptionOpen: true,
      rejectSubscriptionTarget: subscription
    });
  };
  const handleRejectSubscriptionClose = () => {
    setState({
      ...state,
      rejectSubscriptionOpen: false,
      rejectSubscriptionTarget: null
    });
  };
  const handleRejectSubscriptionCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      rejectSubscriptionOpen: false,
      rejectSubscriptionTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const GetSubscriptions = async params => {
    await setState({
      ...state,
      loading: true
    });
    const res = await axios.get(`beinPackagesHistory/requests${params}`);

    // Refreshing Requests Notifcation Count
    if (isAdmin) store.dispatch(getBeinRequestsCount());

    // Mapping the data for the data table
    const subscriptions = res.data.subscriptions.map(sub => ({
      ...sub,
      seller: sub.user.username,
      createdAt: moment(sub.createdAt).format('DD/MM/YYYY - hh:mm')
    }));

    setState({
      ...state,
      subscriptions,
      totalSubscriptions: res.data.totalSubscriptions,
      loading: false
    });
  };

  const {
    subscriptions,
    totalSubscriptions,
    loading,
    requestSubscriptionOpen,
    acceptSubscriptionOpen,
    acceptSubscriptionTarget,
    rejectSubscriptionOpen,
    rejectSubscriptionTarget,
    tableKey
  } = state;

  // #region Actions

  // Adding actions to our table
  const actions = [];
  // Row
  if (isAdmin) {
    actions.push(
      new Action(
        'row',
        'accept-sub',
        'Accept',
        'accept-subscription',
        handleAcceptSubscriptionOpen
      )
    );
    actions.push(
      new Action(
        'row',
        'accept-sub',
        'Reject',
        'stop-subscription',
        handleRejectSubscriptionOpen
      )
    );
  }

  // #endregion Actions

  return (
    <Page className={classes.root} title="BeIN Subscriptions Requests">
      <Container maxWidth={false}>
        {!isAdmin && (
          <Toolbar
            handleRequestSubscriptionOpen={handleRequestSubscriptionOpen}
          />
        )}
        <Box mt={3}>
          {' '}
          <DataTable
            key={tableKey}
            rows={subscriptions}
            totalrows={totalSubscriptions}
            loading={loading ? 1 : 0}
            actions={actions}
            settings={tableSettings}
            rowsGetter={GetSubscriptions}
            rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
          />
        </Box>

        {requestSubscriptionOpen && (
          <RequestSubscription
            open={requestSubscriptionOpen}
            onClose={handleRequestSubscriptionClose}
            closeAfterAdd={handleRequestSubscriptionCloseAfterAdd}
          />
        )}
        {acceptSubscriptionOpen && (
          <AcceptSubscription
            open={acceptSubscriptionOpen}
            target={acceptSubscriptionTarget}
            onClose={handleAcceptSubscriptionClose}
            closeAfterAccept={handleAcceptSubscriptionCloseAfterAdd}
          />
        )}
        {rejectSubscriptionOpen && (
          <RejectSubscription
            open={rejectSubscriptionOpen}
            target={rejectSubscriptionTarget}
            onClose={handleRejectSubscriptionClose}
            closeAfterReject={handleRejectSubscriptionCloseAfterAdd}
          />
        )}
      </Container>
    </Page>
  );
};

BeinSubscriptionsRequestsView.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(BeinSubscriptionsRequestsView);
