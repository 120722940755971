/* eslint-disable no-unused-expressions */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable space-before-function-paren */
import axios from 'axios';
import {
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  GET_CONTACT_LIST,
  ADD_MESSAGE,
  SET_CONTACT,
  UNSET_CONTACT,
  SET_OLDER_MESSAGES_LOADING,
  GET_OLDER_MESSAGES,
  SET_CONTACT_LIST_LOADING,
  UNSET_CONTACT_LIST_LOADING,
  FILTER_CONTACT_LIST
} from '../actions/types';

const initialState = {
  messages: [],
  messagesLoading: true,
  olderMessagesLoading: false,
  olderMessagesOffset: 10,
  olderMessagesLimitReached: false,
  contact: 0,
  contactList: [],
  contactListFirstLoading: true,
  contactListLoading: true,
  contactFilterList: [],
  contactFiltering: false,
  contactFilterListLoading: false,
  unreadMessages: []
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MESSAGE: {
      // When the user sends or recieves a new message, the contact will be put on top
      const contactInfo = state.contactList.find(
        user => payload.receiverId === user.id || payload.senderId === user.id
      );

      const newContactList = state.contactList.filter(
        user => contactInfo.id !== user.id
      );

      newContactList.unshift(contactInfo);

      // We will increase the counter the unread messages
      const messageIndex = state.unreadMessages.findIndex(
        message => payload.senderId === message.id
      );

      const newUnreadMessages = [...state.unreadMessages];

      if (payload.senderId === state.contact) {
        // If the added message is from the selected contact, we read it
        axios.put(`messages/read/message/${payload.id}`);
      } else {
        messageIndex >= 0
          ? (newUnreadMessages[messageIndex].count += 1)
          : newUnreadMessages.push({ id: payload.senderId, count: 1 });
      }

      // If the sender has the same id of the selected contact, we add the message
      const newMessages =
        payload.senderId === state.contact ||
        payload.receiverId === state.contact
          ? [...state.messages, payload]
          : [...state.messages];

      return {
        ...state,
        messages: [...newMessages],
        contactList: [...newContactList],
        unreadMessages: [...newUnreadMessages]
      };
    }
    case GET_MESSAGES: {
      const messageIndex = state.unreadMessages.findIndex(
        message => message.id === state.contact
      );

      const newState = {
        ...state,
        messages: payload,
        messagesLoading: false,
        unreadMessages: state.unreadMessages.map((message, index) =>
          index === messageIndex ? { ...message, count: 0 } : message
        )
      };

      return newState;
    }
    case GET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: [...payload]
      };
    case GET_CONTACT_LIST:
      return {
        ...state,
        contactList: payload,
        contactListLoading: false,
        contactListFirstLoading: false
      };
    case SET_CONTACT:
      return {
        ...state,
        contact: payload,
        messages: [],
        messagesLoading: true,
        olderMessagesLoading: false,
        olderMessagesOffset: 10,
        olderMessagesLimitReached: false
      };
    case UNSET_CONTACT:
      return {
        ...state,
        contact: 0,
        messages: [],
        messagesLoading: true,
        olderMessagesLoading: false,
        olderMessagesOffset: 10,
        olderMessagesLimitReached: false
      };
    case SET_OLDER_MESSAGES_LOADING:
      return {
        ...state,
        olderMessagesLoading: true
      };
    case GET_OLDER_MESSAGES:
      return {
        ...state,
        messages: [...payload, ...state.messages],
        olderMessagesLoading: false,
        olderMessagesOffset: state.olderMessagesOffset + 10,
        olderMessagesLimitReached: payload.length < 10
      };
    case SET_CONTACT_LIST_LOADING:
      return {
        ...state,
        contactFiltering: true,
        contactFilterListLoading: true
      };
    case UNSET_CONTACT_LIST_LOADING:
      return {
        ...state,
        contactFilterList: [],
        contactFiltering: false,
        contactFilterListLoading: false
      };
    case FILTER_CONTACT_LIST:
      return {
        ...state,
        contactFilterList: payload,
        contactFiltering: true,
        contactFilterListLoading: false
      };
    default:
      return state;
  }
}
