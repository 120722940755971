import { Grid, TextField } from '@material-ui/core';
import React from 'react';

const Search = ({ search, onChange }) => {
  return (
    <>
      <Grid item xs={12} style={{ padding: '8px' }}>
        <TextField
          value={search}
          onChange={onChange}
          id="outlined-basic-email"
          label="Search"
          variant="outlined"
          fullWidth
        />
      </Grid>
    </>
  );
};

export default Search;
