/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import Action from 'src/classes/Action';

import { ADMIN } from 'src/strings/roles';

import Toolbar from './Toolbar';
import DeleteSubscription from './DeleteSubscription';
import StopSubscription from './StopSubscription';
import tableSettings from './tableSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubscriptionsView = ({ user }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    subscriptions: [],
    totalSubscriptions: 0,
    loading: true,
    stopSubscriptionOpen: false,
    stopSubscriptionTarget: null,
    deleteSubscriptionOpen: false,
    deleteSubscriptionTarget: null,
    tableKey: 0,
    // We use this to redirect the page to the specific pack when clicking on Subscription's Cards
    redirect: false,
    redirectTarget: 0
  });
  const handleStopSubscriptionOpen = e => {
    const { subscriptions } = state;
    const subscriptionId = Number(e.currentTarget.getAttribute('row-id'));
    const sub = subscriptions.filter(item => {
      return item.id === subscriptionId;
    })[0];

    setState({
      ...state,
      stopSubscriptionOpen: true,
      stopSubscriptionTarget: sub
    });
  };

  const handleStopSubscriptionClose = () => {
    setState({
      ...state,
      stopSubscriptionOpen: false,
      stopSubscriptionTarget: null
    });
  };

  const handleStopSubscriptionCloseAfterStop = () => {
    setState(prevState => ({
      ...state,
      stopSubscriptionOpen: false,
      stopSubscriptionTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const handleDeleteSubscriptionOpen = e => {
    const { subscriptions } = state;
    const subscriptionId = Number(e.currentTarget.getAttribute('row-id'));
    const sub = subscriptions.filter(item => {
      return item.id === subscriptionId;
    })[0];

    setState({
      ...state,
      deleteSubscriptionOpen: true,
      deleteSubscriptionTarget: sub
    });
  };

  const handleDeleteSubscriptionClose = () => {
    setState({
      ...state,
      deleteSubscriptionOpen: false,
      deleteSubscriptionTarget: null
    });
  };

  const handleDeleteSubscriptionCloseAfterDelete = () => {
    setState(prevState => ({
      ...state,
      deleteSubscriptionOpen: false,
      deleteSubscriptionTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const GetSubscriptions = async params => {
    await setState({
      ...state,
      loading: true
    });
    const res = await axios.get(`subscriptions${params}`);

    // Mapping the data for the data table
    const subscriptions = res.data.subscriptions.map(sub => ({
      ...sub,
      seller: sub.user.username,
      startDate: moment(sub.startDate).format('DD/MM/YYYY - hh:mm'),
      stopDate: moment(sub.stopDate).format('DD/MM/YYYY - hh:mm'),
      renewDate: !sub.renewDate
        ? 'No Renew'
        : moment(sub.renewDate).format('DD/MM/YYYY - hh:mm'),
      running: sub.isRunning ? 'Yes' : 'No',
      isRunning: sub.isRunning,
      isDeletable: !sub.isRunning
    }));

    setState({
      ...state,
      subscriptions,
      totalSubscriptions: res.data.totalSubscriptions,
      loading: false
    });
  };

  const {
    subscriptions,
    totalSubscriptions,
    loading,
    tableKey,
    stopSubscriptionOpen,
    stopSubscriptionTarget,
    deleteSubscriptionOpen,
    deleteSubscriptionTarget
  } = state;

  // #region Actions

  // Adding actions to our table
  const actions = [];
  // Head
  // actions.push(new Action('head', 'refresh', 'Cards', 'refresh', HandleTest));
  // Row
  actions.push(
    new Action(
      'row',
      'delete-pack',
      'Stop',
      'stop-subscription',
      handleStopSubscriptionOpen,
      'isRunning'
    )
  );
  actions.push(
    new Action(
      'row',
      'subscription-running',
      'Delete',
      'delete',
      handleDeleteSubscriptionOpen,
      'isDeletable'
    )
  );

  // #endregion Actions

  return (
    <Page className={classes.root} title="Subscriptions">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <DataTable
            key={tableKey}
            rows={subscriptions}
            totalrows={totalSubscriptions}
            loading={loading ? 1 : 0}
            actions={actions}
            settings={tableSettings}
            rowsGetter={GetSubscriptions}
            rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
          />
        </Box>
        {stopSubscriptionOpen && (
          <StopSubscription
            open={stopSubscriptionOpen}
            target={stopSubscriptionTarget}
            onClose={handleStopSubscriptionClose}
            closeAfterStop={handleStopSubscriptionCloseAfterStop}
          />
        )}
        {deleteSubscriptionOpen && (
          <DeleteSubscription
            open={deleteSubscriptionOpen}
            target={deleteSubscriptionTarget}
            onClose={handleDeleteSubscriptionClose}
            closeAfterDelete={handleDeleteSubscriptionCloseAfterDelete}
          />
        )}
      </Container>
    </Page>
  );
};

SubscriptionsView.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(SubscriptionsView);
