/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  GET_BEIN_REQUESTS_NOTIFICATIONS,
  GET_BEIN_HISTORY_NOTIFICATIONS
} from './types';

// Get beIN Requests count
export const getBeinRequestsCount = () => async dispatch => {
  try {
    const res = await axios.get('beinPackagesHistory/requests/unseen');

    dispatch({
      type: GET_BEIN_REQUESTS_NOTIFICATIONS,
      payload: res.data
    });
  } catch (error) {
    const { response } = error;

    console.log(response);
  }
};

// Get beIN History count
export const getBeinHistoryCount = () => async dispatch => {
  try {
    const res = await axios.get('beinPackagesHistory/unseen');

    dispatch({
      type: GET_BEIN_HISTORY_NOTIFICATIONS,
      payload: res.data
    });
  } catch (error) {
    const { response } = error;

    console.log(response);
  }
};
