import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Button, makeStyles } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles({
  buttonProgress: { color: green[500], top: '50%', left: '50%' }
});

function LoadingButton({
  text,
  variant,
  color,
  align,
  disabled,
  loading,
  onClick,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      color={color}
      align={align}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        text
      )}
    </Button>
  );
}

LoadingButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

LoadingButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  align: 'right',
  text: 'Button',
  disabled: false
};

export default LoadingButton;
