module.exports = {
  headCells: [
    { id: 'id', label: 'Id' },
    { id: 'cardNumber', label: 'Card Number' },
    { id: 'packageName', label: 'Package Name' },
    { id: 'price', label: 'Price' },
    { id: 'seller', label: 'Seller' },
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Date' },
    { id: 'actions', label: '' }
  ]
};
