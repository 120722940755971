/* eslint-disable max-len */
import React, { useEffect, useRef, useState, Fragment } from 'react';
import {
  List,
  makeStyles,
  Typography,
  Box,
  CircularProgress
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { MessageLeft, MessageRight } from './chat/Messagelr';

const useStyles = makeStyles(() => ({
  messageArea: {
    height: '55vh',
    overflowY: 'auto'
  },
  seen: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '25px'
  },
  icon: {
    fontSize: '12px',
    marginLeft: '4px'
  }
}));

const ListMessages = ({
  messages,
  readMessage,
  user,
  contact,
  olderMessagesLoading,
  olderMessagesOffset,
  olderMessagesLimitReached,
  getOlderMessages
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    isAtBottom: false,
    loaded: false
  });

  // scroll last message

  const messagesEndRef = useRef(null);
  const messageAreaRef = useRef(null);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevValues = usePrevious({ messages });

  const scrollToTopChecker = () => {
    const { loaded, isAtBottom } = state;
    const distanceFromTop = messageAreaRef.current.scrollTop;

    const { scrollHeight, scrollTop, clientHeight } = messageAreaRef.current;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    if (distanceFromBottom < 30 && !isAtBottom) {
      setState({ ...state, isAtBottom: true });
    } else if (distanceFromBottom >= 30 && isAtBottom) {
      setState({ ...state, isAtBottom: false });
    }

    if (
      loaded &&
      !olderMessagesLimitReached &&
      !olderMessagesLoading &&
      distanceFromTop < 11
    ) {
      getOlderMessages(contact, olderMessagesOffset);
    }
  };

  const scrollToBottomStart = () => {
    if (messages.length > 0) {
      messagesEndRef.current.scrollIntoView();
      setState({ ...state, loaded: true });
    }
  };

  const scrollToBottom = () => {
    if (messages.length > 0) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      setState({ ...state, loaded: true });
    }
  };

  useEffect(scrollToBottomStart, []);
  // useEffect(scrollToBottom, [messages]);
  useEffect(() => {
    const { isAtBottom } = state;

    if (messages.length > 0 && prevValues?.messages.length > 0) {
      const { messages: prevMessages } = prevValues;
      const lastPreviousMessage = prevMessages[prevMessages.length - 1];
      const lastMessage = messages[messages.length - 1];

      // The condition needed for the sender to scorll down automatically after a new message is sent
      const senderScrollCondition = lastMessage.senderId === user.id;

      // The condition needed for the receiver to scorll down automatically after a new message is sent
      const receiverScrollCondition =
        isAtBottom && lastMessage.receiverId === user.id;

      if (
        lastMessage.id > lastPreviousMessage.id &&
        (senderScrollCondition || receiverScrollCondition)
      ) {
        scrollToBottom();
      }
    }
  }, [messages]);

  if (messages.length < 1) {
    return (
      <div>
        <Box className={classes.messageArea} textAlign="center" pt={5}>
          <Typography>
            You don&apos;t have messages with this user yet
          </Typography>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <List
        ref={messageAreaRef}
        className={classes.messageArea}
        id="messageArea"
        onScroll={scrollToTopChecker}
      >
        {olderMessagesLoading && (
          <Box p={5} style={{ margin: '0 auto', display: 'block' }}>
            <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
          </Box>
        )}
        {/* message users  */}
        {messages.map((message, index) => {
          if (message.content !== '') {
            if (message.senderId === user.id) {
              return (
                <Fragment key={message.id}>
                  <MessageRight
                    key={message.id}
                    message={message.content}
                    avatarDisp={false}
                    timeStamp={message.timeStamp}
                  />
                  {/*                   <div className={classes.seen}>
                    {messages.length - 1 === index && message.isRead && (
                      <>
                        <DoneAllIcon className={classes.icon} />
                        <p className={classes.icon}> Seen</p>
                      </>
                    )}
                  </div> */}
                </Fragment>
              );
            }
            return (
              <Fragment key={message.id}>
                <MessageLeft
                  key={message.id}
                  message={message.content}
                  timeStamp={message.timeStamp}
                  avatarDisp={false}
                />
              </Fragment>
            );
          }
        })}
        <div ref={messagesEndRef} />
      </List>
    </div>
  );
};

export default ListMessages;
