import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select } from '@material-ui/core';

function AsyncDropdown({
  label,
  name,
  optionLabel,
  placeholder,
  options,
  value,
  loading,
  handleChange
}) {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="demo-dialog-native">{label}</InputLabel>
      <Select
        name={name}
        disabled={loading}
        native
        value={value}
        onChange={handleChange}
      >
        {loading ? (
          <option value={0} disabled>
            Loading...
          </option>
        ) : (
          <>
            <option value={0} disabled>
              {placeholder}
            </option>
            {options.map(option => (
              <option key={option.id} value={option.id}>
                {option[optionLabel]}
              </option>
            ))}
          </>
        )}
      </Select>
    </FormControl>
  );
}

AsyncDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

AsyncDropdown.defaultProps = {
  placeholder: 'Select...',
  loading: false
};
export default AsyncDropdown;
