import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

function ActionModal(props) {
  const { open, title, size, actionButtons, handleClose, children } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={size}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ paddingBottom: '1em' }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {actionButtons}
      </DialogActions>
    </Dialog>
  );
}

ActionModal.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf(['md', 'xs']),
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

ActionModal.defaultProps = {
  size: 'md'
};

export default ActionModal;
