import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, CircularProgress, Box } from '@material-ui/core';
import CounterWidget from 'src/components/Widgets/CounterWidget';

const Statistics = () => {
  const [state, setState] = useState({
    totalCards: 0,
    totalSoldCards: 0,
    remainingCards: 0,
    totalSellers: 0,
    loading: true
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('/statistics');

      const {
        totalSellers,
        totalCards,
        totalSoldCards,
        remainingCards
      } = res.data;

      setState({
        ...state,
        totalSellers,
        totalCards,
        totalSoldCards,
        remainingCards,
        loading: false
      });
    };
    fetchData();
  }, []);

  const {
    loading,
    totalCards,
    totalSellers,
    totalSoldCards,
    remainingCards
  } = state;
  if (loading) {
    return (
      <Box p={5} style={{ margin: '0 auto', display: 'block' }}>
        <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
      </Box>
    );
  }
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item md={3} xs={6}>
        <CounterWidget
          color="rgb(3, 169, 244)"
          icon="credit-card"
          start={0}
          end={totalCards}
          duration={2}
          title="Total Cards"
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <CounterWidget
          color="rgb(156, 39, 176)"
          icon="money"
          start={0}
          end={totalSoldCards}
          duration={2}
          title="Total Sold Cards"
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <CounterWidget
          color="rgb(0, 150, 136)"
          icon="credit-card-gift"
          start={0}
          end={remainingCards}
          duration={2}
          title="Remaining Cards"
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <CounterWidget
          color="rgb(236, 64, 122)"
          icon="group"
          start={0}
          end={totalSellers}
          duration={2}
          title="Total Sellers"
        />
      </Grid>
    </Grid>
  );
};

export default Statistics;
