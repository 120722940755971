import React, { useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  User as UserIcon,
  PlayCircle as PlayCircleIcon,
  CreditCard as CreditCardIcon,
  Package as PackageIcon,
  LogOut as LogOutIcon,
  MessageSquare as MessageSquareIcon,
  BarChart2 as BarChartIcon,
  Tv as TvIcon,
  AlertCircle as AlertCircleIcon
} from 'react-feather';
import { ADMIN, SELLER } from 'src/strings/roles';
import store from 'src/store';
import { logout } from 'src/actions/auth';
import { getBeinRequestsCount, getBeinHistoryCount } from 'src/actions/ui';

import NavItem from './NavItem';

// const user = {
//   avatar: '/static/images/avatars/avatar_6.png',
//   jobTitle: 'Senior Developer',
//   name: 'Katarina Smith'
// };

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    access: [ADMIN, SELLER]
  },
  {
    href: '/dashboard/smart-cards',
    icon: CreditCardIcon,
    title: 'Smart Cards',
    access: [ADMIN, SELLER]
  },

  {
    href: '/dashboard/packages',
    icon: PackageIcon,
    title: 'Packages',
    access: [ADMIN]
  },
  {
    href: '/dashboard/sellers',
    icon: UserIcon,
    title: 'Sellers',
    access: [ADMIN, SELLER]
  },
  {
    href: '/dashboard/subscriptions',
    icon: PlayCircleIcon,
    title: 'Subscriptions',
    access: [ADMIN, SELLER]
  },
  {
    href: '/dashboard/bein-subscriptions/packages',
    icon: PackageIcon,
    title: 'beIN Packages',
    access: [ADMIN]
  },
  {
    href: '/dashboard/bein-subscriptions/requests',
    icon: AlertCircleIcon,
    title: 'beIN Requests',
    access: [ADMIN, SELLER],
    badgeName: 'beInRequestsNotificationCount'
  },
  {
    href: '/dashboard/bein-subscriptions',
    icon: TvIcon,
    title: 'beIN History',
    access: [ADMIN, SELLER],
    badgeName: 'beInHistoryNotificationCount'
  },
  {
    href: '/dashboard/settings',
    icon: SettingsIcon,
    title: 'Settings',
    access: [ADMIN, SELLER]
  }
];

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  name: {
    marginTop: '12px'
  }
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  user,
  ui: uiState,
  messagesTotalCount
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isAdmin = user.role.code === ADMIN;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (isAdmin) {
      store.dispatch(getBeinRequestsCount());
    } else store.dispatch(getBeinHistoryCount());
  }, []);

  // Each 10 seconds, we call the backend to check if there are any new pending bein subscription
  useInterval(() => {
    if (isAdmin) {
      store.dispatch(getBeinRequestsCount());
    } else store.dispatch(getBeinHistoryCount());
  }, 30 * 1000);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/dashboard/settings"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.username}
        </Typography>
        {user.role.code === SELLER && (
          <Typography className={classes.name} color="textPrimary" variant="h6">
            {`Credit: ${user.credit}`}
          </Typography>
        )}
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => {
            if (!item.access.includes(user.role.code)) return false;
            return (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                badge={uiState[item.badgeName]}
              />
            );
          })}
          <Hidden lgUp>
            <NavItem
              href="/dashboard/messages"
              title="Messages"
              icon={MessageSquareIcon}
              active={false}
              badge={messagesTotalCount}
            />
          </Hidden>
          <Hidden lgUp>
            <NavItem
              onClick={() => store.dispatch(logout())}
              href="#"
              title="Logout"
              icon={LogOutIcon}
              active={false}
            />
          </Hidden>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  messagesTotalCount: PropTypes.number.isRequired
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = state => ({
  user: state.auth.user,
  ui: state.ui
});

export default connect(mapStateToProps)(NavBar);
