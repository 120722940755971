import React from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Container,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotAuthorized = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="401">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            401: You are not authorizesd to visit this page
          </Typography>
          {/*           <Typography align="center" color="textPrimary" variant="subtitle2">
            If you believe that this is a mistake, please contact the admin
          </Typography> */}
          <Box textAlign="center" style={{ marginTop: '2em' }}>
            <Button
              onClick={() => {
                navigate('/');
              }}
              color="secondary"
              variant="contained"
            >
              Get back to home page
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotAuthorized;
