import React from 'react';

import { useRoutes } from 'react-router-dom';

import BeinPackagesView from './BeinPackagesView';
import BeinSubscriptionsRequestsView from './BeinSubscriptionsRequestsView';
import BeinSubscriptionsView from './BeinSubscriptionsView';

const routes = [
  {
    path: '/',
    children: [
      { path: '/', element: <BeinSubscriptionsView /> },
      { path: '/packages', element: <BeinPackagesView /> },
      { path: '/requests', element: <BeinSubscriptionsRequestsView /> }
    ]
  }
];

function BeinSubscriptionsRoute() {
  const routing = useRoutes(routes);
  return <>{routing}</>;
}

export default BeinSubscriptionsRoute;
