import React from 'react';
import {
  ListItem,
  ListItemText,
  Divider,
  Badge,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: '#f1f1f1'
  }
}));

const ContactListItem = ({
  contact,
  contactItem,
  unreadMessages,
  setContact,
  handleClose
}) => {
  const classes = useStyles();

  const contactUnreadMessages = unreadMessages.find(
    message => message.id === contactItem.id
  );

  const unreadMessagesCount = contactUnreadMessages
    ? contactUnreadMessages.count
    : 0;

  const handleOnClick = () => {
    setContact(contactItem.id);

    handleClose();
  };

  return (
    <>
      <ListItem
        button
        className={contact === contactItem.id ? classes.selected : ''}
        onClick={handleOnClick}
      >
        <ListItemText primary={contactItem.username}>
          {contactItem.username}
        </ListItemText>
        <ListItemText align="right">
          <Badge badgeContent={unreadMessagesCount} max={9} color="error" />
        </ListItemText>
      </ListItem>
      <Divider />
    </>
  );
};

export default ContactListItem;
