/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  ADD_MESSAGE,
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  GET_CONTACT_LIST,
  SET_CONTACT,
  UNSET_CONTACT,
  READ_MESSAGES,
  READ_MESSAGE,
  SET_OLDER_MESSAGES_LOADING,
  GET_OLDER_MESSAGES,
  SET_CONTACT_LIST_LOADING,
  UNSET_CONTACT_LIST_LOADING,
  FILTER_CONTACT_LIST
} from './types';

// Get Unread Messages
export const readMessages = userId => async dispatch => {
  await axios.put(`messages/read/${userId}`);

  dispatch({ type: READ_MESSAGES });
};

// Read Messages
export const readMessage = messageId => async dispatch => {
  await axios.put(`messages/read/message/${messageId}`);

  dispatch({ type: READ_MESSAGE });
};

// Get Unread Messages
export const getUnredMessages = () => async dispatch => {
  const res = await axios.get('messages/unread');

  dispatch({ type: GET_UNREAD_MESSAGES, payload: res.data });
};

// Get Messages
export const getMessages = userId => async dispatch => {
  if (userId === 0) {
    dispatch({ type: GET_MESSAGES, payload: [] });
  } else {
    const res = await axios.get(`messages/${userId}`);

    dispatch({ type: GET_MESSAGES, payload: res.data });
    dispatch(readMessages(userId));
  }
};

// Get Messages
export const getOlderMessages = (userId, offset) => async dispatch => {
  dispatch({ type: SET_OLDER_MESSAGES_LOADING });

  const res = await axios.get(`messages/${userId}?offset=${offset}`);

  dispatch({ type: GET_OLDER_MESSAGES, payload: res.data });
};

export const addMessage = messageData => async dispatch => {
  dispatch({ type: ADD_MESSAGE, payload: messageData });
};

// Get Messages
export const sendMessage = messageData => async dispatch => {
  const res = await axios.post('messages/', messageData);

  dispatch(addMessage(res.data));

  return res.data;
};

export const getContacts = () => async dispatch => {
  const res = await axios.get('messages/contactList');

  dispatch({ type: GET_CONTACT_LIST, payload: res.data });
  dispatch(getUnredMessages());

  return res.data;

  // const firstContactId = res.data.length > 0 ? res.data[0].id : 0;

  // if (firstContactId !== 0) {
  //   dispatch(getMessages(firstContactId));
  // }
};

export const filterContacts = search => async dispatch => {
  if (search === '') {
    dispatch({ type: UNSET_CONTACT_LIST_LOADING });
  } else {
    dispatch({ type: SET_CONTACT_LIST_LOADING });

    const res = await axios.get(`messages/contactList?search=${search}`);

    dispatch({ type: FILTER_CONTACT_LIST, payload: res.data });
  }
};

export const setContact = contactId => async dispatch => {
  dispatch({ type: SET_CONTACT, payload: contactId });

  dispatch(getMessages(contactId));
};

export const unsetContact = () => async dispatch => {
  dispatch({ type: UNSET_CONTACT });
};
