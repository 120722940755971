import React from 'react';
import PropTypes from 'prop-types';

import GroupIcon from '@material-ui/icons/Group';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const CounterWidgetIcon = ({ classes, icon }) => {
  switch (icon) {
    case 'group':
      return <GroupIcon className={classes.counterIcon} />;
    case 'credit-card':
      return <CreditCardIcon className={classes.counterIcon} />;
    case 'credit-card-gift':
      return <CardGiftcardIcon className={classes.counterIcon} />;
    case 'money':
      return <LocalAtmIcon className={classes.counterIcon} />;
    default:
      return <></>;
  }
};

CounterWidgetIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string
};

export default CounterWidgetIcon;
