module.exports = {
  headCells: [
    { id: 'id', label: 'Id' },
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'package', label: 'Package' },
    { id: 'casId', label: 'Id CAS' },
    { id: 'serialNumber', label: 'Serial No' },
    { id: 'startDate', label: 'Start Date' },
    { id: 'stopDate', label: 'Stop Date' },
    { id: 'renewDate', label: 'Renew Date' },
    { id: 'seller', label: 'Seller' },
    { id: 'running', label: 'Running' },
    { id: 'actions', label: '' }
  ]
};
