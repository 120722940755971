import React from 'react';
import { Navigate } from 'react-router-dom';

import { ADMIN, SELLER } from 'src/strings/roles';
import PrivateRoute from 'src/components/Routing/PrivateRoute';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import NotAuthorized from 'src/views/errors/NotAuthorized';
// import RegisterView from 'src/views/auth/RegisterView';
import DashboardPage from 'src/views/dashboard/DashboardPage';
import MessagesPage from 'src/views/messages/MessagesPage';
import SmartCards from 'src/views/cards/SmartCards';
import SubscriptionsRoute from 'src/views/subscriptions/SubscriptionsRoute';
import SellersRoute from 'src/views/sellers/SellersRoute';
import PackagesRoute from 'src/views/packages/PackagesRoute';
import BeinSubscriptionsRoute from 'src/views/bein-subscriptions/BeinSubscriptionsRoute';

// import SettingsView from 'src/views/settings/SettingsView';
// import ProductListView from 'src/views/product/ProductListView';
// import DashboardView from 'src/views/reports/DashboardView';
// import CustomerListView from 'src/views/customer/CustomerListView';

const routes = [
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <DashboardPage /> },
      { path: 'messages', element: <MessagesPage /> },
      { path: 'settings', element: <AccountView /> },
      // { path: 'sellers', element: <SellersView /> },
      {
        path: 'sellers',
        element: <SellersRoute path="/dashboard/sellers" />,
        children: [{ path: '/' }, { path: ':id' }]
      },
      {
        path: 'packages',
        element: <PrivateRoute access={[ADMIN]} component={PackagesRoute} />,
        children: [{ path: '/' }, { path: ':id' }]
      },
      {
        path: 'bein-subscriptions',
        element: (
          <PrivateRoute
            access={[ADMIN, SELLER]}
            component={BeinSubscriptionsRoute}
          />
        ),
        children: [{ path: '/' }, { path: '/packages' }, { path: '/requests' }]
      },
      // { path: 'statistics', element: <DashboardView /> },
      {
        path: 'subscriptions',
        element: <SubscriptionsRoute path="/dashboard/sellers" />,
        children: [{ path: '/' }, { path: ':id' }]
      },
      // { path: 'settings', element: <SettingsView /> },
      {
        path: 'smart-cards',
        element: (
          <PrivateRoute access={[ADMIN, SELLER]} component={SmartCards} />
        )
      },
      { path: 'unauthorized', element: <NotAuthorized /> },
      { path: '*', element: <NotFoundView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      // { path: 'register', element: <RegisterView /> },
      { path: 'unauthorized', element: <NotAuthorized /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
