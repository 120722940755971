import React from 'react';
import PropTypes from 'prop-types';

import { /*  Checkbox,  */ TableCell, TableRow, Grid } from '@material-ui/core';

import TableRowItem from './TableRowItem';

function TableRows({
  rows,
  loading,
  actions,
  headCells,
  selectedRowsIds,
  handleSelectOne
}) {
  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={12}>
          <Grid
            container
            alignItems="flex-start"
            justify="center"
            direction="row"
          >
            Loading...
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  if (rows.length <= 0) {
    return (
      <TableRow>
        <TableCell colSpan={12}>
          <Grid
            container
            alignItems="flex-start"
            justify="center"
            direction="row"
          >
            No data to display
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  return rows.map(row => (
    <TableRowItem
      key={row.id}
      row={row}
      headCells={headCells}
      actions={actions}
      selectedRowsIds={selectedRowsIds}
      handleSelectOne={handleSelectOne}
    />
  ));
}

TableRows.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  loading: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  selectedRowsIds: PropTypes.array.isRequired,
  handleSelectOne: PropTypes.func.isRequired
};

export default TableRows;
