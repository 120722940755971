/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import ToolbarAction from './ToolbarAction';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
    padding: 10
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

function Toolbar(props) {
  const classes = useStyles();

  const { actions, search, handleRefresh, handleSearchChange } = props;

  return (
    <Box
      className={classes.importButton}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        {' '}
        <TextField
          fullWidth
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          placeholder="Search..."
          variant="outlined"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <ToolbarAction
          className="refresh"
          tooltip="Refresh"
          icon="refresh"
          func={handleRefresh}
        />
        {actions
          .filter(cell => cell.place === 'head')
          .map((action, index) => (
            <ToolbarAction
              key={index}
              className={action.className}
              tooltip={action.tooltip}
              icon={action.icon}
              func={action.func}
            />
            // eslint-disable-next-line indent
          ))}
      </Box>
    </Box>
  );
}

Toolbar.propTypes = {
  actions: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired
};

export default Toolbar;
