/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import ActionModal from 'src/components/Modals/ActionModal';

import { Grid, Typography, Divider } from '@material-ui/core';

function RejectSubscription({
  open,
  target: { id: subscriptionId, user, packageName, price, cardNumber },
  onClose,
  closeAfterReject
}) {
  const [state, setState] = useState({
    submitLoading: false,
    errorMessage: ''
  });

  const handleSubmit = async () => {
    await setState({
      ...state,
      submitLoading: true,
      errorMessage: ''
    });

    try {
      await axios.put(`/beinPackagesHistory/reject/${subscriptionId}`);

      await setState({
        ...state,
        submitLoading: false
      });

      closeAfterReject();
    } catch (error) {
      const {
        response: {
          data: { msg }
        }
      } = error;

      setState({
        ...state,
        submitLoading: false,
        errorMessage: msg
      });
    }
  };

  const { submitLoading, errorMessage } = state;
  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title="Reject Subscription"
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={submitLoading}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            Do you want to reject the subscription with the ID{' '}
            <strong>{subscriptionId}</strong>?
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />
          <Typography gutterBottom>
            Card Number: <strong>{cardNumber}</strong>
          </Typography>
          <Typography gutterBottom>
            Package Name: <strong>{packageName}</strong>
          </Typography>{' '}
          <Typography gutterBottom>
            Package Price: <strong>{price}</strong>
          </Typography>{' '}
          <Typography gutterBottom>
            Seller: <strong>{user.username}</strong>
          </Typography>{' '}
          <Typography gutterBottom>
            Seller&apos;s Credit: <strong>{user.credit}</strong>
          </Typography>
        </Grid>

        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
}

RejectSubscription.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  closeAfterReject: PropTypes.func.isRequired
};

RejectSubscription.defaultProps = {
  open: false
};

export default RejectSubscription;
