import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

import Search from '../Search';
import ContactList from '../contactList/ContactList';

const DialogChat = ({ onClose, selectedValue, open, filterContacts }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    filterContacts(search);
  }, [search]);

  const onSearchChange = e => {
    setSearch(e.target.value);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Search search={search} onChange={onSearchChange} />
        {/* friends */}
        <ContactList search={search} handleClose={handleClose} />
      </Dialog>
    </>
  );
};
DialogChat.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default DialogChat;
