/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable object-curly-newline */
import React from 'react';
import {
  createUltimatePagination,
  ITEM_TYPES
} from 'react-ultimate-pagination';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import NavigationFirstPage from '@material-ui/icons/FirstPage';
import NavigationLastPage from '@material-ui/icons/LastPage';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const flatButtonStyle = {
  minWidth: 36
};

const styles = {
  paging: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pageDropdown: {
    paddingTop: '4.5px!important',
    paddingBottom: '4.5px!important'
  },
  paginationText: {
    paddingBottom: '18.5px!important'
  }
};

const Page = ({ value, isActive, onClick, isDisabled }) => (
  <Button
    style={flatButtonStyle}
    color={isActive ? 'secondary' : 'default'}
    onClick={onClick}
    disabled={isDisabled}
  >
    {value.toString()}
  </Button>
);

Page.propTypes = {
  value: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const Ellipsis = ({ onClick, isDisabled }) => (
  <Button style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
    ...
  </Button>
);

Ellipsis.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const FirstPageLink = ({ onClick, isDisabled }) => (
  <IconButton style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
    <NavigationFirstPage />
  </IconButton>
);

FirstPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const PreviousPageLink = ({ onClick, isDisabled }) => (
  <IconButton style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
    <NavigationChevronLeft />
  </IconButton>
);

PreviousPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const NextPageLink = ({ onClick, isDisabled }) => (
  <IconButton style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
    <NavigationChevronRight />
  </IconButton>
);

NextPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const LastPageLink = ({ onClick, isDisabled }) => (
  <IconButton style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
    <NavigationLastPage />
  </IconButton>
);

LastPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

const UltmPagination = createUltimatePagination({ itemTypeToComponent });

class TablePagination extends React.Component {
  constructor(props) {
    super();
    this.state = {
      totalPages: props.totpages
    };
  }

  render() {
    const hide = true;
    const { totalPages } = this.state;
    const {
      classes,
      curpage,
      totalRows,
      rowsCount,
      rowsPerPage,
      onChange,
      onGoFirst,
      onPrev,
      onNext,
      onGoLast,
      handleChangeRowsPerPage,
      ...rest
    } = this.props;

    const offset = rowsCount === 0 ? 0 : (curpage - 1) * rowsPerPage + 1;
    const offsetEnd = offset === 0 ? 0 : offset - 1 + rowsCount;

    return (
      <>
        {' '}
        <div className={classes.paging}>
          <FirstPageLink isDisabled={curpage <= 1} onClick={onGoFirst} />
          <PreviousPageLink isDisabled={curpage <= 1} onClick={onPrev} />
          <Hidden xsDown>
            <UltmPagination
              currentPage={curpage}
              totalPages={totalPages}
              onChange={onChange}
              hidePreviousAndNextPageLinks={hide}
              hideFirstAndLastPageLinks={hide}
              {...rest}
            />
          </Hidden>
          <NextPageLink isDisabled={curpage >= totalPages} onClick={onNext} />
          <LastPageLink isDisabled={curpage >= totalPages} onClick={onGoLast} />
          <TextField
            id="outlined-select-currency"
            select
            value={10}
            size="small"
            style={{ padding: '12px' }}
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                className: classes.pageDropdown
              }
            }}
            // variant="outlined"
          >
            <MenuItem value={10}>{10}</MenuItem>
            <MenuItem value={50}>{50}</MenuItem>
            <MenuItem value={100}>{100}</MenuItem>
            <MenuItem value={250}>{250}</MenuItem>
            <MenuItem value={500}>{500}</MenuItem>
            <MenuItem value={1000}>{1000}</MenuItem>
          </TextField>
        </div>
        <div className={classes.paging}>
          <Typography className={classes.paginationText} pb={3}>
            {`Showing ${offset} to ${offsetEnd} of ${totalRows} entries`}
          </Typography>
        </div>
      </>
    );
  }
}

TablePagination.propTypes = {
  curpage: PropTypes.number.isRequired,
  totpages: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  rowsCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onGoFirst: PropTypes.func.isRequired,
  onGoLast: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

TablePagination.defaultProps = {
  rowsPerPage: 10
};

export default withStyles(styles)(TablePagination);
