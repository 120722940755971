import React from 'react';
import { connect } from 'react-redux';
import { List, ListItem, CircularProgress, Box } from '@material-ui/core';

import { setContact } from 'src/actions/chat';

import ContactListItem from './ContactListItem';

const ContactList = ({
  search,
  contact,
  contactList,
  contactFiltering,
  contactFilterList,
  contactFilterListLoading,
  unreadMessages,
  setContact,
  handleClose
}) => {
  if (contactFilterListLoading) {
    return (
      <List>
        <ListItem>
          {' '}
          <Box p={1} style={{ margin: '0 auto', display: 'block' }}>
            <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
          </Box>
        </ListItem>
      </List>
    );
  }
  if (search && contactFiltering) {
    return (
      <List>
        {contactFilterList.map(contactItem => {
          return (
            <ContactListItem
              key={contactItem.id}
              contact={contact}
              contactItem={contactItem}
              unreadMessages={unreadMessages}
              setContact={setContact}
              handleClose={handleClose}
            />
          );
        })}
      </List>
    );
  }
  return (
    <List>
      {contactList.map(contactItem => {
        return (
          <ContactListItem
            key={contactItem.id}
            contact={contact}
            contactItem={contactItem}
            unreadMessages={unreadMessages}
            setContact={setContact}
            handleClose={handleClose}
          />
        );
      })}
    </List>
  );
};

const mapStateToProps = state => ({
  contact: state.chat.contact,
  contactList: state.chat.contactList,
  contactFilterList: state.chat.contactFilterList,
  contactFiltering: state.chat.contactFiltering,
  contactFilterListLoading: state.chat.contactFilterListLoading,
  unreadMessages: state.chat.unreadMessages
});

export default connect(mapStateToProps, {
  setContact
})(ContactList);
