import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Box,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import store from 'src/store';
import { login, setLoginErrorMessage } from 'src/actions/auth';

const backgroundUrl =
  'https://images.pexels.com/photos/275484/pexels-photo-275484.jpeg';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `   linear-gradient(
      rgba(0, 0, 0, 0.8), 
      rgba(0, 0, 0, 0.8)
    ), url(${backgroundUrl}) no-repeat`,
    backgroundSize: 'cover'
  },
  loginBox: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: '14px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }
}));

const LoginView = ({ isAuthenticated, loginErrorMessage }) => {
  const classes = useStyles();
  const recaptchaRef = React.useRef({});

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.loginBox}>
          <Formik
            initialValues={{
              username: 'admin',
              password: '123456'
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .max(255)
                .required('Username is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={async (formData, { setSubmitting }) => {
              store.dispatch(setLoginErrorMessage(''));

              const { username, password } = formData;

              // store.dispatch(login(username, password));
              const recaptchaValue = recaptchaRef.current.getValue();
              if (recaptchaValue) {
                await store.dispatch(login(username, password));
                recaptchaRef.current.reset();
              } else {
                await store.dispatch(setLoginErrorMessage('Captcha Error'));
              }

              setSubmitting(false);

              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2} display="flex" justifyContent="center">
                  {' '}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  />
                </Box>

                {loginErrorMessage && (
                  <Box my={2}>
                    <AlertMessage message={loginErrorMessage} />
                  </Box>
                )}
                <Box my={2}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="secondary"
                    align="right"
                    text="Sign in"
                    size="large"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

LoginView.propTypes = {
  isAuthenticated: PropTypes.bool,
  loginErrorMessage: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginErrorMessage: state.auth.loginErrorMessage
});

export default connect(mapStateToProps)(LoginView);
