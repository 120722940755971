/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import AlertMessage from 'src/components/Messages/AlertMessage';
import LoadingButton from 'src/components/Buttons/LoadingButton';
import AsyncDropdown from 'src/components/Forms/AsyncDropdown';
import ActionModal from 'src/components/Modals/ActionModal';

import { Grid, Typography, TextField } from '@material-ui/core';

const RequestSubscription = ({
  user: { credit },
  open,
  onClose,
  closeAfterAdd
}) => {
  const [state, setState] = useState({
    cardNumber: '',
    pack: 0,
    packages: [],
    packagesLoading: true,
    submitLoading: false,
    errorMessage: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      await setState({
        ...state,
        packagesLoading: true
      });

      try {
        const res = await axios.get('beinPackages/');

        let packages = res.data.map(pack => ({
          ...pack,
          label: `${pack.label} (Price: ${pack.price})`
        }));

        await setState({
          ...state,
          pack: res.data[0] ? res.data[0].id : 0,
          packages,
          packagesLoading: false
        });
      } catch (error) {
        console.log(error);

        setState({
          ...state,
          packagesLoading: false
        });
      }
    };

    fetchData();
  }, []);
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChangeDropdown = e => {
    const {
      target: { name }
    } = e;
    const value = Number(e.target.value);
    setState({
      ...state,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      submitLoading: true,
      errorMessage: ''
    });

    const { pack: packageId, cardNumber } = state;

    try {
      await axios.post('beinPackagesHistory/', { packageId, cardNumber });

      setState(prevState => ({
        ...prevState,
        submitLoading: false
      }));

      closeAfterAdd();
    } catch (error) {
      const { response } = error;

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
        errorMessage: response.data.msg
      }));
    }
  };

  const {
    cardNumber,
    pack,
    packages,
    packagesLoading,
    submitLoading,
    errorMessage
  } = state;

  const selectedPack = packages.find(_pack => _pack.id === pack);

  return (
    <ActionModal
      open={open}
      handleClose={onClose}
      title="Request a Subscription"
      actionButtons={
        <>
          {' '}
          <LoadingButton
            variant="contained"
            color="secondary"
            align="right"
            text="Confirm"
            loading={submitLoading}
            disabled={
              submitLoading ||
              !cardNumber ||
              pack === 0 ||
              selectedPack.price > credit
            }
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            Current credit: <strong>{credit}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            onChange={handleChange}
            required
            value={cardNumber}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncDropdown
            label="Package*"
            name="pack"
            optionLabel="label"
            placeholder="Select a package"
            options={packages}
            value={pack}
            loading={packagesLoading}
            handleChange={handleChangeDropdown}
          />
        </Grid>
        {selectedPack && (
          <Grid item xs={12}>
            {selectedPack.price <= credit ? (
              <Typography>
                Remianing credit after buying:{' '}
                <strong>{credit - selectedPack.price}</strong>
              </Typography>
            ) : (
              <Typography style={{ color: 'red' }}>
                You don't have enough credit for this package
              </Typography>
            )}
          </Grid>
        )}

        {errorMessage && (
          <Grid item xs={12}>
            <AlertMessage message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </ActionModal>
  );
};

RequestSubscription.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  closeAfterAdd: PropTypes.func.isRequired
};

RequestSubscription.defaultProps = {
  open: false
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(RequestSubscription);
