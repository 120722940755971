import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import { ADMIN } from 'src/strings/roles';

import ProfileDetails from './ProfileDetails';
// import Administration from './Administration';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = ({ user }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProfileDetails user={user} />
          </Grid>
          {/*           {user.role.code === ADMIN && (
            <Grid item xs={12}>
              <Administration user={user} />
            </Grid>
          )} */}
        </Grid>
      </Container>
    </Page>
  );
};

Account.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(Account);
