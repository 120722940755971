/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import axios from 'axios';
import { connect } from 'react-redux';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DataTable from 'src/components/tables/DataTable';
import Action from 'src/classes/Action';
import { ADMIN } from 'src/strings/roles';

import Toolbar from './Toolbar';
import AddSeller from './AddSeller';
import EditSeller from './EditSeller';
import DeleteSeller from './DeleteSeller';
import BlockSeller from './BlockSeller';
import UnblockSeller from './UnblockSeller';
import EditCredit from './EditCredit';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SellersView = ({ user }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    sellers: [],
    totalSellers: 0,
    loading: true,
    addSellerOpen: false,
    editSellerOpen: false,
    editSellerTarget: null,
    deleteSellerOpen: false,
    deleteSellerTarget: null,
    blockSellerOpen: false,
    blockSellerTarget: null,
    unblockSellerOpen: false,
    unblockSellerTarget: null,
    editCreditOpen: false,
    editCreditTarget: null,
    tableKey: 0,
    // We use this to redirect the page to the specific seller when clicking on Seller's Cards
    redirect: false,
    redirectTarget: 0
  });

  const handleAddSellerOpen = () => {
    setState({
      ...state,
      addSellerOpen: true
    });
  };
  const handleAddSellerClose = () => {
    setState({
      ...state,
      addSellerOpen: false
    });
  };
  const handleAddSellerCloseAfterAdd = () => {
    setState(prevState => ({
      ...state,
      addSellerOpen: false,
      tableKey: prevState.tableKey + 1
    }));
  };

  const handleEditSellerOpen = e => {
    const { sellers } = state;
    const sellerId = Number(e.currentTarget.getAttribute('row-id'));
    const seller = sellers.filter(item => {
      return item.id === sellerId;
    })[0];

    setState({
      ...state,
      editSellerOpen: true,
      editSellerTarget: seller
    });
  };

  const handleEditSellerClose = () => {
    setState({
      ...state,
      editSellerOpen: false,
      editSellerTarget: null
    });
  };

  const handleEditSellerCloseAfterEdit = () => {
    setState(prevState => ({
      ...state,
      editSellerOpen: false,
      editSellerTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const HandleDeleteSeller = e => {
    const { sellers } = state;
    const sellerId = Number(e.currentTarget.getAttribute('row-id'));
    const seller = sellers.filter(item => {
      return item.id === sellerId;
    })[0];

    setState({
      ...state,
      deleteSellerOpen: true,
      deleteSellerTarget: seller
    });
  };

  const handleDeleteSellerClose = () => {
    setState({
      ...state,
      deleteSellerOpen: false,
      deleteSellerTarget: null
    });
  };

  const handleDeleteSellerCloseAfterDelete = () => {
    setState(prevState => ({
      ...state,
      deleteSellerOpen: false,
      deleteSellerTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const HandleBlockSeller = e => {
    const { sellers } = state;
    const sellerId = Number(e.currentTarget.getAttribute('row-id'));
    const seller = sellers.filter(item => {
      return item.id === sellerId;
    })[0];

    setState({
      ...state,
      blockSellerOpen: true,
      blockSellerTarget: seller
    });
  };

  const handleBlockSellerClose = () => {
    setState({
      ...state,
      blockSellerOpen: false,
      blockSellerTarget: null
    });
  };

  const handleBlockSellerCloseAfterBlock = () => {
    setState(prevState => ({
      ...state,
      blockSellerOpen: false,
      blockSellerTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const HandleUnblockSeller = e => {
    const { sellers } = state;
    const sellerId = Number(e.currentTarget.getAttribute('row-id'));
    const seller = sellers.filter(item => {
      return item.id === sellerId;
    })[0];

    setState({
      ...state,
      unblockSellerOpen: true,
      unblockSellerTarget: seller
    });
  };

  const handleUnblockSellerClose = () => {
    setState({
      ...state,
      unblockSellerOpen: false,
      unblockSellerTarget: null
    });
  };

  const handleUnblockSellerCloseAfterUnblock = () => {
    setState(prevState => ({
      ...state,
      unblockSellerOpen: false,
      unblockSellerTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const handleEditCreditOpen = e => {
    const { sellers } = state;
    const sellerId = Number(e.currentTarget.getAttribute('row-id'));
    const seller = sellers.filter(item => {
      return item.id === sellerId;
    })[0];

    setState({
      ...state,
      editCreditOpen: true,
      editCreditTarget: seller
    });
  };

  const handleEditCreditClose = () => {
    setState({
      ...state,
      editCreditOpen: false,
      editCreditTarget: null
    });
  };

  const handleEditCreditCloseAfterEdit = () => {
    setState(prevState => ({
      ...state,
      editCreditOpen: false,
      editCreditTarget: null,
      tableKey: prevState.tableKey + 1
    }));
  };

  const GetSellers = async params => {
    await setState({
      ...state,
      loading: true
    });
    const res = await axios.get(`sellers${params}`);

    // Mapping the data for the data table
    const sellers = res.data.sellers.map(seller => ({
      ...seller,
      packages:
        seller.packages.length > 0
          ? seller.packages.map((pack, index) => {
              if (index !== seller.packages.length - 1) {
                return `${pack.label}, `;
              }
              return pack.label;
            })
          : 'None',
      packagesData: seller.packages,
      isBlocked: seller.blocked ? 'Yes' : 'No'
    }));

    setState({
      ...state,
      sellers,
      totalSellers: res.data.totalSellers,
      loading: false
    });
  };

  const HandleRedirectToSellerCards = event => {
    const redirectTarget = Number(event.currentTarget.getAttribute('row-id'));
    setState({ ...state, redirect: true, redirectTarget });
  };

  const {
    sellers,
    totalSellers,
    loading,
    tableKey,
    redirect,
    redirectTarget,
    addSellerOpen,
    editSellerOpen,
    editSellerTarget,
    deleteSellerOpen,
    deleteSellerTarget,
    blockSellerOpen,
    blockSellerTarget,
    unblockSellerOpen,
    unblockSellerTarget,
    editCreditOpen,
    editCreditTarget
  } = state;

  // #region Actions

  // Adding actions to our table
  const actions = [];
  // Head
  // actions.push(new Action('head', 'refresh', 'Cards', 'refresh', HandleRedirectToSellerCards));
  // Row
  actions.push(
    new Action(
      'row',
      'seller-cards',
      'Cards',
      'credit-card',
      HandleRedirectToSellerCards
    )
  );
  if (user.role.code === ADMIN) {
    actions.push(
      new Action(
        'row',
        'edit-credit',
        'Edit Credit',
        'dollar-sign',
        handleEditCreditOpen
      )
    );
  }
  actions.push(
    new Action('row', 'edit-seller', 'Edit', 'edit', handleEditSellerOpen)
  );

  actions.push(
    new Action(
      'row',
      'unblock',
      'Unblock',
      'accept-subscription',
      HandleUnblockSeller,
      'blocked'
    )
  );

  actions.push(
    new Action(
      'row',
      'block',
      'Block',
      'stop-subscription',
      HandleBlockSeller,
      'notBlocked'
    )
  );

  actions.push(
    new Action('row', 'delete-seller', 'Delete', 'delete', HandleDeleteSeller)
  );

  // #endregion Actions

  // Redirect if Seller's Cards button is clicked
  if (redirect) {
    return <Navigate push to={`/dashboard/sellers/${redirectTarget}`} />;
  }

  const mappedTableSettings = {
    headCells: [
      { id: 'username', label: 'Username' },
      { id: 'packages', label: 'Packages', unsortable: true }
    ]
  };

  if (user.role.code === ADMIN) {
    mappedTableSettings.headCells = [
      ...mappedTableSettings.headCells,
      { id: 'api_key', label: 'API Key' },
      { id: 'credit', label: 'Credit' }
    ];
  }

  mappedTableSettings.headCells = [
    ...mappedTableSettings.headCells,
    { id: 'region', label: 'Region' },
    { id: 'isBlocked', label: 'Blocked' },
    { id: 'actions', label: '' }
  ];

  return (
    <Page className={classes.root} title="Sellers">
      <Container maxWidth={false}>
        <Toolbar handleAddSellerOpen={handleAddSellerOpen} />
        <Box mt={3}>
          <DataTable
            key={tableKey}
            rows={sellers}
            totalrows={totalSellers}
            loading={loading ? 1 : 0}
            actions={actions}
            settings={mappedTableSettings}
            rowsGetter={GetSellers}
            rowsPerPageOptions={[10, 50, 100, 250, 500, 1000]}
          />
        </Box>
        {addSellerOpen && (
          <AddSeller
            open={addSellerOpen}
            user={user}
            onClose={handleAddSellerClose}
            closeAfterAdd={handleAddSellerCloseAfterAdd}
          />
        )}
        {editSellerOpen && (
          <EditSeller
            open={editSellerOpen}
            user={user}
            target={editSellerTarget}
            onClose={handleEditSellerClose}
            closeAfterEdit={handleEditSellerCloseAfterEdit}
          />
        )}
        {deleteSellerOpen && (
          <DeleteSeller
            open={deleteSellerOpen}
            target={deleteSellerTarget}
            onClose={handleDeleteSellerClose}
            closeAfterDelete={handleDeleteSellerCloseAfterDelete}
          />
        )}
        {blockSellerOpen && (
          <BlockSeller
            open={blockSellerOpen}
            target={blockSellerTarget}
            onClose={handleBlockSellerClose}
            closeAfterBlock={handleBlockSellerCloseAfterBlock}
          />
        )}
        {unblockSellerOpen && (
          <UnblockSeller
            open={unblockSellerOpen}
            target={unblockSellerTarget}
            onClose={handleUnblockSellerClose}
            closeAfterUnblock={handleUnblockSellerCloseAfterUnblock}
          />
        )}
        {editCreditOpen && (
          <EditCredit
            open={editCreditOpen}
            target={editCreditTarget}
            onClose={handleEditCreditClose}
            closeAfterEdit={handleEditCreditCloseAfterEdit}
          />
        )}
        {editCreditOpen && (
          <EditCredit
            open={editCreditOpen}
            target={editCreditTarget}
            onClose={handleEditCreditClose}
            closeAfterEdit={handleEditCreditCloseAfterEdit}
          />
        )}
      </Container>
    </Page>
  );
};

SellersView.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(SellersView);
