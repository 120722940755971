/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import { CreditCard, Edit2, Trash2, RefreshCcw } from 'react-feather';

const ToolbarAction = ({ className, icon, func, tooltip }) => {
  const iconSetter = name => {
    switch (name) {
      case 'refresh':
        return <RefreshCcw />;
      default:
        return <></>;
    }
  };

  return (
    <Tooltip title={tooltip}>
      <IconButton className={className} onClick={func} aria-label={tooltip}>
        {iconSetter(icon)}
      </IconButton>
    </Tooltip>
  );
};
ToolbarAction.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired
};

export default ToolbarAction;
